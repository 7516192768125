import React, { useState } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const JsonImporter = ({ onImport }) => {
  const [error, setError] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        onImport(json); // Pass the parsed JSON to the parent component
        setError(""); // Clear any previous error
      } catch (error) {
        console.error("Error parsing JSON file", error);
        setError("Invalid JSON file.");
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <Button variant="outlined" component="label">
        Import JSON
        <input
          type="file"
          accept="application/json"
          hidden
          onChange={handleFileUpload} // Handle file upload
        />
      </Button>
      {error && <Alert severity="error">{error}</Alert>}{" "}
      {/* Display error if any */}
    </div>
  );
};

export default JsonImporter;
