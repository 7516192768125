import React, { useContext } from "react";
import { useSnackbar } from "./FeedbackSnackbar";
import { MyContext } from "../../context/Context";
import { fetchAuthSession } from "aws-amplify/auth";

export const useApiRequest = () => {
  const { triggerSnackbar } = useSnackbar();
  const { state, dispatch } = useContext(MyContext);
  const { onSuccessfulModification } = useContext(MyContext);

  const incrementLoading = () => {
    dispatch({ type: "INCREMENT_LOADING" });
  };

  const decrementLoading = () => {
    dispatch({ type: "DECREMENT_LOADING" });
  };

  const fetchTable = async (tableName) => {
    incrementLoading();
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    try {
      const session = await fetchAuthSession();
      const validToken = session.tokens.accessToken;
      if (!validToken) throw new Error("Invalid or expired token");

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${validToken}`,
        },
        body: JSON.stringify({
          command: "command_any_table",
          obj: { command: "get_all_items", item: {}, table_name: tableName },
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Fetching tables failed with status ${response.status}`
        );
      }

      const data = await response.json();
      dispatch({
        type: "SET_DATABASE_DATA",
        payload: { ...state.database, [tableName]: data },
      });
    } catch (error) {
      console.error("Failed to fetch tables:", error.message);
      triggerSnackbar(`Fetching tables failed: ${error.message}`, "error");
    } finally {
      decrementLoading();
    }
  };

  const makeApiRequest = async (url, options = {}, tableName, onSuccess) => {
    incrementLoading();

    try {
      const session = await fetchAuthSession();
      const validToken = session.tokens.accessToken;
      if (!validToken) throw new Error("Invalid or expired token");

      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${validToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok)
        throw new Error(`Request failed with status ${response.status}`);

      const data = await response.json();
      // const successMessage = `Success: ${url}`;
      const successMessage = `Success`;
      triggerSnackbar(successMessage, "success");

      if (onSuccess && typeof onSuccess === "function") onSuccess(data);

      if (tableName) {
        await fetchTable(tableName);
      }

      onSuccessfulModification();
      return { success: true, data };
    } catch (error) {
      triggerSnackbar(`Request failed: ${error.message}`, "error");
      return { success: false, error: error.message };
    } finally {
      decrementLoading();
    }
  };

  return { makeApiRequest, fetchTable };
};
