import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MyProvider } from "./context/Context";
import NavBar from "./components/NavBar";
import "./App.css";
import { Box, CircularProgress } from "@mui/material";
import Database from "./components/Database/Database";
import Settings from "./components/Settings/Settings";
import Schedules from "./components/Schedules/Schedules";
import Events from "./components/Events/Events";
import Infrastructure from "./components/Infrastructure/Infrastructure";
import ThemeContextProvider from "./context/ThemeContextProvider";
import Footer from "./components/Footer";
import { useAuthenticator } from "@aws-amplify/ui-react"; // Use Amplify's hook for auth
import Flows from "./components/Flows/Flows";

function ControlTower() {
  const { user, signOut, isLoading } = useAuthenticator(); // useAuthenticator hook to get user and signOut
  const hasListenerBeenAdded = useRef(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // You could handle token refresh or session re-check here if necessary
      }
    };

    // Add the event listener for visibility changes
    if (!hasListenerBeenAdded.current) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      hasListenerBeenAdded.current = true;
    }

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <MyProvider>
      <ThemeContextProvider>
        <Router>
          <div className="appContainer">
            <div className="content">
              <NavBar signOut={signOut} />{" "}
              {/* Passing signOut from useAuthenticator */}
              <Routes>
                <Route path="/" element={<Events />} />
                <Route path="/events" element={<Events />} />
                <Route path="/database" element={<Database />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/schedules" element={<Schedules />} />
                <Route path="/infrastructure" element={<Infrastructure />} />
                <Route path="/flow" element={<Flows />} />
              </Routes>
            </div>
            <Footer userEmail={user.signInDetails.loginId} />
          </div>
        </Router>
      </ThemeContextProvider>
    </MyProvider>
  );
}

export default ControlTower;
