import React from "react";
import "./App.css"; // Ensure your custom styles are defined here
import { Amplify } from "aws-amplify";
import { Authenticator, View, Header, Image } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import ControlTower from "./ControlTower";
import { SnackbarProvider } from "./components/Utility/FeedbackSnackbar";

Amplify.configure(awsExports);
const components = {
  SignIn: {
    Header() {
      return (
        <View textAlign="center">
          <img
            style={{ paddingTop: "20px", maxWidth: "100px" }}
            alt="HBS logo"
            src="/logo192.png"
          />
          <p>HBMC Tower</p>
        </View>
      );
    },
  },
};

function App() {
  return (
    <Authenticator hideSignUp={true} components={components}>
      {({ signOut, user }) => {
        return (
          <div>
            <SnackbarProvider>
              <ControlTower signOut={signOut}></ControlTower>
            </SnackbarProvider>
          </div>
        );
      }}
    </Authenticator>
  );
}

export default App;
