import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PublicIcon from "@mui/icons-material/Public";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CastIcon from "@mui/icons-material/Cast";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
function MixTvuMediaHubDetails({ mixConfig }) {
  const [copySuccess, setCopySuccess] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      (err) => {
        console.error("Error copying text to clipboard", err);
      }
    );
  };

  return (
    <Box>
      {/* Displaying Basic Details */}
      <Box display="flex" gap="10px" alignItems="center" height="30px">
        <p>
          <strong>{mixConfig.profile.alias}</strong>
        </p>
      </Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <CastIcon></CastIcon>
        <p>
          Destination route:{" "}
          <strong>{mixConfig.route_name.toUpperCase()} </strong>
        </p>
      </Box>
      <Box display="flex" alignItems="center" gap="10px" height="30px">
        <PublicIcon />
        <p>
          Region: <strong>{mixConfig.profile.region}</strong>
        </p>
      </Box>
      <Box display="flex" alignItems="center" gap="10px" height="30px">
        <VideoSettingsIcon></VideoSettingsIcon>
        <p>
          Transcoding Profile:{" "}
          <strong>{mixConfig.profile.encoding_profile.profile_name}</strong>
        </p>
      </Box>
    </Box>
  );
}

export default MixTvuMediaHubDetails;
