import React, { useContext } from "react";
import ElixirService from "./ElixirService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button } from "@mui/material";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

export default function Elixir() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const elixirServices = state.status.mix?.elixir;
  const hasServices =
    elixirServices &&
    Object.keys(elixirServices).some(
      (region) => elixirServices[region].length > 0
    );

  return (
    <div>
      <Card>
        {hasServices ? (
          Object.entries(elixirServices).map(([region, services], index) => (
            <React.Fragment key={index}>
              {services.map((service, svcIndex) => (
                <div key={svcIndex}>
                  <p>{region}</p>
                  <ElixirService service={service} />
                </div>
              ))}
            </React.Fragment>
          ))
        ) : (
          <Typography style={{ margin: "20px" }}>
            No containers running...
          </Typography>
        )}
      </Card>
    </div>
  );
}
