import { useState } from "react";
import moment from "moment-timezone";

const useCronTranslator = (timezone) => {
  const translateCronExpression = (cronExpression) => {
    const match = cronExpression.match(
      /cron\((\d+)\s+(\d+)\s+(\d+)\s+(\d+)\s+\?\s+(\d+)\)/
    );
    if (match) {
      let [, minute, hour, day, month, year] = match;
      minute = minute.padStart(2, "0");

      // Treat the date as UTC first, then convert to the specified timezone
      const utcDate = moment.utc(
        `${year}-${month}-${day} ${hour}:${minute}`,
        "YYYY-MM-DD HH:mm"
      );

      // Now convert the UTC date to the target timezone
      const date = utcDate.tz(timezone);

      return date.format("YYYY-MM-DD HH:mm");
    }
    return "Invalid cron expression";
  };

  return translateCronExpression;
};

export default useCronTranslator;
