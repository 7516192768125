// ScheduleUpdater.js

import React, { useContext, useState } from "react";
import { MyContext } from "../../context/Context";
import BoltIcon from "@mui/icons-material/Bolt";
import { Button, Box } from "@mui/material";
import CustomDateTimePicker from "../Utility/CustomDateTimePicker";
import PublishIcon from "@mui/icons-material/Publish";
import useUnixMsToDate from "../Utility/useUnixMsToDate";

const ScheduleUpdater = ({ onUpdateTime }) => {
  const [selectedDate, setSelectedDate] = useState(null); // Start with null to indicate no date selected
  const [selectedTimezone, setSelectedTimezone] = useState(null); // Start with null to indicate no timezone selected
  const translateUnixExpression = useUnixMsToDate();
  const { state } = useContext(MyContext);

  const handleUpdateClick = () => {
    if (selectedDate && selectedTimezone) {
      // Assuming this function can handle a dayjs object or format it as needed
      onUpdateTime(selectedDate, selectedTimezone);
      console.log(selectedDate, selectedTimezone);
    }
  };

  const handleRunNowClick = () => {
    // Get the current date and time
    const currentDate = new Date();

    // Add 1 minute (60,000 milliseconds) to the current date
    const newDate = new Date(currentDate.getTime() + 120000);

    // Convert newDate to a Unix timestamp in milliseconds
    const unixTimestampMs = newDate.getTime();

    // Log the current date + 1 minute as Unix timestamp in milliseconds
    console.log(
      "Current time + 1 minute (Unix Timestamp in ms):",
      unixTimestampMs
    );
    console.log(unixTimestampMs, state.settings.timezone);
    onUpdateTime(unixTimestampMs, state.settings.timezone);

    // Optionally, you could trigger the onUpdateTime function with the new timestamp
  };

  return (
    <div style={{ marginBottom: "20px", paddingLeft: "10vw" }}>
      <Box display="flex" gap={3} alignItems="center">
        <CustomDateTimePicker
          onConfirm={(date, timezone) => {
            setSelectedDate(date);
            setSelectedTimezone(timezone);
          }}
          edgyButton={true}
        />
        <Button
          variant="contained"
          disabled={selectedDate == null}
          onClick={handleUpdateClick}
          style={{
            marginLeft: "5px",
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
          }}
        >
          <PublishIcon></PublishIcon> Update
        </Button>

        <Button
          style={{
            marginLeft: "30px",
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
          }}
          variant="contained"
          color="secondary"
          onClick={handleRunNowClick}
        >
          <BoltIcon></BoltIcon> Run in 2min
        </Button>
        {/* Display the selected date */}
      </Box>
      {selectedDate && (
        <>
          <p>
            <strong>
              New selected time to run the schedule:{" "}
              {/* New scheduled time: {selectedDate} ({selectedTimezone}) :{" "} */}
              {translateUnixExpression(selectedDate, state.settings.timezone)}
            </strong>
          </p>
        </>
      )}
    </div>
  );
};

export default ScheduleUpdater;
