import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Input, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { Card } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Papa from "papaparse";
import Typography from "@mui/material/Typography";
import JsonImporter from "../../Utility/JsonImporter";
const AddTakerProfileForm = ({ open, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    takers: [],
  });
  const [takerItem, setTakerItem] = useState({
    name: "",
    connection_limit: "2",
    passphrase: "",
    latency: "300",
    mode: "listener",
    address: "0.0.0.0",
    port: "0",
  });

  const [error, setError] = useState("");
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleFileInputChange = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFormData({
          ...formData,
          takers: [...results.data],
        });
      },
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTakerItemChange = (event) => {
    const { name, value } = event.target;
    setTakerItem({ ...takerItem, [name]: value });
  };

  const addTakerItem = () => {
    if (
      !takerItem.name ||
      !takerItem.connection_limit ||
      !takerItem.passphrase ||
      !takerItem.latency ||
      !takerItem.mode ||
      !takerItem.address
    ) {
      setError("All taker item fields are required.");
      return;
    }
    setError("");
    setFormData({
      ...formData,
      takers: [...formData.takers, takerItem],
    });
    // Reset taker item form
    setTakerItem({
      name: "",
      connection_limit: "2",
      passphrase: "",
      latency: "300",
      mode: "listener",
      address: "0.0.0.0",
      port: "0",
    });
  };

  const handleModeChange = (value) => {
    if (value == "listener") {
      setTakerItem({
        ...takerItem,
        address: "0.0.0.0",
        port: "",
      });
    }
    setTakerItem({
      ...takerItem,
      mode: value,
    });
  };

  const removeTakerItem = (uidToRemove) => {
    setFormData({
      ...formData,
      takers: formData.takers.filter(({ name }) => name !== uidToRemove),
    });
  };

  const validateData = () => {
    if (!formData.uid || !formData.alias || formData.takers.length === 0) {
      setError("UID, Alias, and at least one Taker are required.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = () => {
    if (validateData()) {
      onAdd(formData);
      // Reset the form
      handleClose();
    }
  };

  const handleClose = () => {
    setFormData({ uid: uuidv4(), alias: "", takers: [] });
    setError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Taker Profile</DialogTitle>
      <DialogContent>
        <Box style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Typography variant="h6">CSV Import</Typography>
          <Typography variant="subtitle1">Header:</Typography>
          <Typography variant="body2">
            name,connection_limit,passphrase,latency,mode,address,port
          </Typography>
          <Typography variant="subtitle1">Example Rows:</Typography>
          <Typography variant="body2" style={{ fontSize: "small" }}>
            Taker1,1,dfjndsfnksadf,300,listener,0.0.0.0,0
          </Typography>
          <Typography variant="body2" style={{ fontSize: "small" }}>
            Taker2,3,dfdsgjodfsg,300,listener,0.0.0.0,0
          </Typography>
          <Typography variant="body2" style={{ fontSize: "small" }}>
            Taker3,3,abjnsadfsadf,300,caller,8.8.8.8,9999
          </Typography>

          <Input
            type="file"
            color="primary"
            onChange={handleFileInputChange}
            inputProps={{ accept: ".csv" }}
          />
        </Box>

        <TextField
          autoFocus
          margin="dense"
          name="uid"
          label="UID"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={handleChange}
        />
        <TextField
          autoFocus
          margin="dense"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={handleChange}
        />
        {/* Taker Item Fields */}
        <Card style={{ padding: "10px", margin: "10px" }}>
          <TextField
            margin="dense"
            name="name"
            label="Taker Name"
            type="text"
            fullWidth
            variant="outlined"
            value={takerItem.name}
            onChange={handleTakerItemChange}
          />
          <TextField
            margin="dense"
            name="connection_limit"
            label="Connection Limit"
            type="text"
            fullWidth
            variant="outlined"
            value={takerItem.connection_limit}
            onChange={handleTakerItemChange}
          />
          <TextField
            margin="dense"
            name="passphrase"
            label="Passphrase"
            type="text"
            fullWidth
            variant="outlined"
            value={takerItem.passphrase}
            onChange={handleTakerItemChange}
          />
          <TextField
            margin="dense"
            name="latency"
            label="SRT Latency"
            type="text"
            fullWidth
            variant="outlined"
            value={takerItem.latency}
            onChange={handleTakerItemChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>SRT Mode </InputLabel>
            <Select
              value={takerItem.mode}
              onChange={(e) => handleModeChange(e.target.value)}
              label="SRT Mode"
            >
              <MenuItem key={"caller"} value={"caller"}>
                Caller
              </MenuItem>
              <MenuItem key={"listener"} value={"listener"}>
                Listener
              </MenuItem>
            </Select>
          </FormControl>
          {takerItem.mode == "caller" ? (
            <>
              <TextField
                margin="dense"
                name="address"
                label="Address"
                type="text"
                fullWidth
                variant="outlined"
                value={takerItem.address}
                onChange={handleTakerItemChange}
              />
              <TextField
                margin="dense"
                name="port"
                label="port"
                type="text"
                fullWidth
                variant="outlined"
                value={takerItem.port}
                onChange={handleTakerItemChange}
              />
            </>
          ) : null}
          <Button
            onClick={addTakerItem}
            variant="contained"
            style={{ margin: "10px 0" }}
          >
            Add Taker Item
          </Button>{" "}
        </Card>
        {formData.takers.length > 0 && (
          <Box sx={{ my: 2 }}>
            <div>Added Taker Items:</div>
            {formData.takers.map((taker, index) => (
              <Chip
                key={index}
                label={`${taker.name} (${taker.connection_limit}, ${
                  taker.passphrase
                }, ${taker.latency}, ${taker.mode}${
                  taker.mode === "caller"
                    ? ` ${taker.address}:${taker.port}`
                    : ""
                })`}
                onDelete={() => removeTakerItem(taker.name)}
                color="primary"
                style={{ margin: "5px" }}
              />
            ))}
          </Box>
        )}
        {error && (
          <Alert severity="error" style={{ marginTop: "1rem" }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTakerProfileForm;
