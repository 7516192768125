import React, { useState, useContext } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { MyContext } from "../../context/Context";
import Flow from "./Flow"; // Ensure Flow is correctly imported here

export default function Flows() {
  const { state } = useContext(MyContext);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [config, setConfig] = useState(null);

  const handleEventChange = (event) => {
    const eventId = event.target.value;
    setSelectedEventId(eventId);

    // Find the selected event by ID and set its config
    const selectedEvent = state.database.events.find(
      (ev) => ev.uid === eventId
    );
    setConfig(selectedEvent ? selectedEvent.config : null);
  };

  return (
    <div>
      <FormControl style={{ marginLeft: "30px", minWidth: "250px" }}>
        <InputLabel>Select Event</InputLabel>
        <Select
          value={selectedEventId}
          onChange={handleEventChange}
          label="Select Event"
        >
          {state.database.events
            ?.filter(
              (event) => event.config && Object.keys(event.config).length > 0
            ) // Filter events with non-empty config
            .map((event, index) => (
              <MenuItem key={event.uid + index} value={event.uid}>
                {event.alias}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Render Flow only if a config is selected, using a key to ensure full remount */}
      {config && <Flow key={selectedEventId} config={config} />}
    </div>
  );
}
