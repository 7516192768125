import React from "react";
import { Grid, Typography, Link, Paper } from "@mui/material";

const GatewayDetails = ({ gateway }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        Distributions:{" "}
        {Array.isArray(gateway["distributions"])
          ? gateway["distributions"].join(", ")
          : ""}{" "}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          Host:{" "}
          <Link href={`https://${gateway["host"]}`} underline="hover">
            {gateway["host"]}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Device ID: {gateway["device_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Region: {gateway["region"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>AWS Instance ID: {gateway["aws_instance_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Path: {gateway["path"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {gateway["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {gateway["internal_ip"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default GatewayDetails;
