import moment from "moment-timezone";

const useUnixMsToDate = () => {
  const translateUnixExpression = (timestamp, timezone) => {
    const formattedDate = moment
      .tz(Number(timestamp), timezone)
      .format("MM/DD/YYYY HH:mm:ss");
    return formattedDate;
  };
  return translateUnixExpression;
};

export default useUnixMsToDate;
