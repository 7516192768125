import React, { useContext } from "react";
import { MyContext } from "../../context/Context";
import ContributionSimulatorService from "./ContributionSimulatorService";
import ContributionSimulatorStarter from "./ContributionSimulatorStarter";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

export default function ContributionSimulator() {
  const { state, onSuccessfulModification } = useContext(MyContext);

  const contributionSimulator =
    state.status.contribution?.contribution_simulator;
  const hasServices =
    contributionSimulator &&
    Object.keys(contributionSimulator).some(
      (region) => contributionSimulator[region].length > 0
    );

  return (
    <div>
      <ContributionSimulatorStarter></ContributionSimulatorStarter>
      <Card>
        {hasServices ? (
          Object.entries(contributionSimulator).map(
            ([region, services], index) => (
              <Box style={{ marginLeft: "10px" }}>
                <React.Fragment key={index}>
                  <p>Region: {region}</p>
                  <Box display="flex" gap="20px">
                    {services.map((service, svcIndex) => (
                      <div key={svcIndex} style={{ minWidth: "300px" }}>
                        <ContributionSimulatorService service={service} />
                      </div>
                    ))}
                  </Box>
                </React.Fragment>
              </Box>
            )
          )
        ) : (
          <Typography style={{ margin: "20px" }}>
            No containers running...
          </Typography>
        )}
      </Card>
    </div>
  );
}
