import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import JsonImporter from "../../Utility/JsonImporter";
const AddPromexsProfileForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);

  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    rundown: [],
  });
  const [selectedAbility, setSelectedAbility] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleAddRundownTask = (taskToAdd) => {
    // Check if the rundown already contains a task with the same uid
    // const isAlreadyAdded = formData.rundown.some(
    //   (task) => task.uid === taskToAdd.uid
    // );

    // If not already added, append the new task to the rundown array
    setFormData((prevFormData) => ({
      ...prevFormData,
      rundown: [...prevFormData.rundown, taskToAdd],
    }));
  };

  const handleClose = () => {
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };

  const handleSubmit = () => {
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && formData[key] === "") {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });

    if (isValid) {
      onClose();
      setError("");
      onAdd(formData);
      setFormData({
        uid: uuidv4(),
        alias: "",
        rundown: [],
      });
      console.log(formData);
    }
  };
  const handleRemoveRundownTask = (index) => {
    console.log(index);
    setFormData({
      ...formData,
      rundown: formData.rundown.filter((_, i) => i !== index),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {" "}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1">Add New Promexs Profile </Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          margin="normal"
          name="uid"
          label="Uid"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={(e) => handleChange(e)}
        />
        <FormControl fullWidth margin="normal">
          <FormControl fullWidth margin="normal">
            <InputLabel id="promexs-abilities-select-label">
              Promexs Rundown Tasks{" "}
            </InputLabel>
            <Select
              labelId="promexs-abilities-select-label"
              value={selectedAbility}
              onChange={(e) => setSelectedAbility(e.target.value)}
              label="Promexs Rundwon Tasks"
            >
              {state.database.promexs_abilities.map((ability) => (
                <MenuItem key={ability.uid} value={ability}>
                  {ability.alias}
                </MenuItem>
              ))}
            </Select>
            {selectedAbility ? (
              <TextField
                autoFocus
                margin="normal"
                name="alias"
                label="execution_offset"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedAbility.execution_offset}
                onChange={(e) => {
                  setSelectedAbility({
                    ...selectedAbility,
                    execution_offset: e.target.value,
                  });
                }}
              />
            ) : null}
            <Button
              variant="contained"
              onClick={() => {
                handleAddRundownTask(selectedAbility);
              }}
              style={{ marginTop: "10px" }}
              disabled={selectedAbility == ""}
            >
              Add Rundown Task
            </Button>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                marginTop: "40px",
              }}
            >
              {formData.rundown.map((ability, index) => (
                <Chip
                  key={ability.uid + index}
                  label={ability.alias + " : " + ability.execution_offset}
                  onDelete={() => {
                    handleRemoveRundownTask(index);
                  }}
                  color="primary"
                />
              ))}
            </Box>
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPromexsProfileForm;
