import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Card,
  Paper,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  IconButton,
  MenuItem,
  TableSortLabel,
  TextField,
  Tooltip, // Import for the time filter input
} from "@mui/material";
import ScheduleRow from "./ScheduleRow"; // Adjust the import path as needed
import useCronTranslator from "../Utility/useCronTranslator";
import DataObjectIcon from "@mui/icons-material/DataObject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FunctionsIcon from "@mui/icons-material/Functions";
import Description from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Nirvana from "../Utility/Nirvana";

function ScheduleTable({ schedules, timezone }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("ScheduleExpression");

  const translateCronExpression = useCronTranslator(timezone);

  // General Filter State
  const [filter, setFilter] = useState("");

  // Individual field filters
  const [eventFilter, setEventFilter] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [taskFilter, setTaskFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  // Time Filter
  const [timeFilter, setTimeFilter] = useState(""); // New time filter state

  // Dropdown values
  const [events, setEvents] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [types, setTypes] = useState([]);

  // Extract distinct values for dropdowns
  useEffect(() => {
    if (schedules && schedules.length > 0) {
      const eventSet = new Set();
      const numberSet = new Set();
      const taskSet = new Set();
      const typeSet = new Set();

      schedules.forEach((schedule) => {
        const [event, number, task, type] = schedule.Description.split(";");
        eventSet.add(event);
        numberSet.add(number);
        taskSet.add(task);
        typeSet.add(type);
      });

      setEvents([...eventSet]);
      setNumbers([...numberSet]);
      setTasks([...taskSet]);
      setTypes([...typeSet]);
    }
  }, [schedules]);

  // Apply the filter based on the schedule description and sorting
  const sortedData = schedules
    ? [...schedules]
        .filter((schedule) => {
          const description = schedule.Description || ""; // Ensure Description is a string
          const [event, number, task, type] = description.split(";");

          const scheduleTime = translateCronExpression(
            schedule.ScheduleExpression
          );

          // Apply filters
          const passesGeneralFilter = description
            .toLowerCase()
            .includes(filter.toLowerCase());
          const passesEventFilter = eventFilter
            ? event.toLowerCase() === eventFilter.toLowerCase()
            : true;
          const passesNumberFilter = numberFilter
            ? number.toLowerCase() === numberFilter.toLowerCase()
            : true;
          const passesTaskFilter = taskFilter
            ? task.toLowerCase() === taskFilter.toLowerCase()
            : true;
          const passesTypeFilter = typeFilter
            ? type.toLowerCase() === typeFilter.toLowerCase()
            : true;
          const passesTimeFilter = timeFilter
            ? scheduleTime.includes(timeFilter)
            : true;

          return (
            passesGeneralFilter &&
            passesEventFilter &&
            passesNumberFilter &&
            passesTaskFilter &&
            passesTypeFilter &&
            passesTimeFilter
          );
        })
        .sort((a, b) => {
          const valueA =
            orderBy === "ScheduleExpression"
              ? translateCronExpression(a[orderBy])
              : a[orderBy] || ""; // Fallback to empty string if undefined
          const valueB =
            orderBy === "ScheduleExpression"
              ? translateCronExpression(b[orderBy])
              : b[orderBy] || ""; // Fallback to empty string if undefined
          return (
            (orderDirection === "asc" ? 1 : -1) *
            (valueA < valueB ? -1 : valueA > valueB ? 1 : 0)
          );
        })
    : [];

  // Paginate the sorted data
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleDeleteAllSchedules = async () => {
    try {
      await requestConfirm(
        "Are you sure you want to delete the filtered schedules? (Including additional pages)"
      );
      let all_schedules = [];
      sortedData.map((schedule) => {
        let input = {
          schedule_name: schedule.Name,
          group_name: "default",
        };
        all_schedules.push(input);
      });
      let url = process.env.REACT_APP_DELETE_SCHEDULE_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ schedules: all_schedules }),
        },
        onSuccessfulModification
      );
      return result;
    } catch (error) {
      console.log(error);
      console.log("Delete action cancelled by user");
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset page to zero when rows per page changes
  };

  const clearAllFilters = () => {
    setEventFilter("");
    setNumberFilter("");
    setTaskFilter("");
    setTypeFilter("");
    setTimeFilter("");
  };

  return (
    <Card
      style={{
        minHeight: "120vh",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div style={{ padding: "15px" }}>
        {ConfirmDialog}

        {/* General Filter */}
        <Box mb={2}>
          <Box display="flex" alignItems="center" gap={2}>
            {/* Time Filter */}
            <TextField
              label="Filter by Time "
              variant="outlined"
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              placeholder="(MM/DD/YYYY HH:mm)"
            />

            {/* Dropdowns for event, number, task, and type */}
            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Event</InputLabel>
              <Select
                value={eventFilter}
                onChange={(e) => setEventFilter(e.target.value)}
                label="Event"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {events.map((event) => (
                  <MenuItem key={event} value={event}>
                    {event}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Number</InputLabel>
              <Select
                value={numberFilter}
                onChange={(e) => setNumberFilter(e.target.value)}
                label="Number"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {numbers.map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Task</InputLabel>
              <Select
                value={taskFilter}
                onChange={(e) => setTaskFilter(e.target.value)}
                label="Task"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {tasks.map((task) => (
                  <MenuItem key={task} value={task}>
                    {task}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                label="Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip title="Clear Filters">
              <IconButton onClick={() => clearAllFilters()} aria-label="clear">
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "Description"}
                    direction={
                      orderBy === "Description" ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort("Description")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Description />
                      <Typography variant="body1">
                        <strong>Schedule</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "ScheduleExpression"}
                    direction={
                      orderBy === "ScheduleExpression" ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort("ScheduleExpression")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon />
                      <Typography variant="body1">
                        <strong>Time</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                  <TableSortLabel
                    active={orderBy === "Id"}
                    direction={orderBy === "Id" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("Id")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <FunctionsIcon />
                      <Typography variant="body1">
                        <strong>Id</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell> */}
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "Input"}
                    direction={orderBy === "Input" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("Input")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <DataObjectIcon />
                      <Typography variant="body1">
                        <strong>Input</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Delete all filtered schedules">
                    <IconButton
                      disabled={schedules.length === 0}
                      onClick={() => handleDeleteAllSchedules()}
                    >
                      <DeleteIcon className="icon" color="secondary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Nirvana></Nirvana>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((schedule, index) => (
                  <ScheduleRow
                    key={schedule.Name}
                    schedule={schedule}
                    timezone={timezone}
                    rowIndex={index}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
}

export default ScheduleTable;
