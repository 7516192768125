import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../../context/Context";
import TableAccordions from "./TableAccordions";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function Database() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const skeletonCount = 5; // Number of skeleton accordions you want to show
  return (
    <div style={{ margin: "5px" }}>
      {Object.keys(state.database).length > 0 ? (
        <div>
          <TableAccordions></TableAccordions>
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "90%",
            margin: "auto",
            paddingTop: "5%",
          }}
        >
          {Array.from(new Array(skeletonCount)).map((_, index) => (
            <Box key={index} sx={{ marginBottom: 2 }}>
              {/* Simulate accordion header */}
              <Skeleton
                variant="rectangular"
                width="100%"
                height={50}
                animation="wave"
              />
              {/* Simulate accordion expanded area with varying content */}
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ marginBottom: 1 }}
              />
              <Skeleton variant="text" width="60%" animation="wave" />
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
}
