import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function Nirvana() {
  return (
    <Box
      display="flex"
      style={{ minHeight: "150px", width: "100%", color: "#9e9e9e" }}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <MoreHorizIcon sx={{ fontSize: 40, marginBottom: 1 }} />
      {/* <Typography variant="body1" style={{ color: "#9e9e9e" }}>
        Nothing here
      </Typography> */}
    </Box>
  );
}

export default Nirvana;
