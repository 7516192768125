import React from "react";
import moment from "moment-timezone";

const useDateToUnixMs = () => {
  const convertToUnixMs = (dateTimeString, timezone) => {
    const unixMs = moment.tz(dateTimeString, timezone).valueOf();
    return unixMs;
  };

  return convertToUnixMs;
};

export default useDateToUnixMs;
