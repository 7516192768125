import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import JSONDialog from "../Utility/JSONDialog";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import MixElixirDetails from "./MixElixirDetails";
import MixTvuDetails from "./MixTvuDetails";
import CastIcon from "@mui/icons-material/Cast";
import MixSpalkDetails from "./MixSpalkDetails";
import FilterIcon from "@mui/icons-material/Filter";
import MixTvuMediaHubDetails from "./TvuMediaHubDetails";
import LinkIcon from "@mui/icons-material/Link"; // Import LinkIcon
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Nirvana from "../Utility/Nirvana";
function MixConfigs({ config, eventUid }) {
  const getLinkForType = (type) => {
    switch (type) {
      case "tvu_mediahub":
        return "https://mediahub.tvunetworks.com/mediahub";
      case "tvu":
        return "https://commentator.tvunetworks.com/commentator/event";
      case "spalk":
        return "https://app.spalk.tv/dashboard";
      case "elixir":
        return "https://us-east-1.console.aws.amazon.com/ecs/v2/clusters/elixir-cluster/services?region=us-east-1";
      default:
        return "#"; // Default link in case of unknown type
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      {config.length > 0 ? (
        config.map((mixConfig, mixIndex) => (
          <React.Fragment key={mixIndex}>
            <Paper key={mixIndex} sx={{ m: 2, p: 2, minWidth: "400px" }}>
              <Box display="flex" flexDirection={"column"}>
                <Box
                  display="flex"
                  gap="10px"
                  alignItems={"center"}
                  style={{ borderBottom: "1px solid grey" }}
                >
                  {" "}
                  <Tooltip title={`Open ${mixConfig.type}`}>
                    <IconButton
                      component="a"
                      href={getLinkForType(mixConfig.type)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Open ${mixConfig.type} link`}
                    >
                      <VideoSettingsIcon
                        style={{
                          color:
                            mixConfig.type === "tvu"
                              ? "green"
                              : mixConfig.type === "elixir"
                              ? "purple"
                              : mixConfig.type === "spalk"
                              ? "orange"
                              : mixConfig.type === "tvu_mediahub"
                              ? "#19d104"
                              : "black",
                        }}
                      />{" "}
                    </IconButton>
                  </Tooltip>
                  <Typography variant="h6">
                    {mixConfig.type.toUpperCase()}
                  </Typography>
                  <Box
                    style={{
                      marginLeft: "auto",
                      paddingBottom: "5px",
                    }}
                  >
                    <JSONDialog
                      input_json={mixConfig.profile}
                      name={mixConfig.profile.alias}
                    ></JSONDialog>
                  </Box>
                </Box>

                {mixConfig.type === "elixir" && (
                  <MixElixirDetails
                    mixConfig={mixConfig}
                    mixIndex={mixIndex}
                    eventUid={eventUid}
                  />
                )}
                {mixConfig.type === "tvu" && (
                  <MixTvuDetails
                    mixConfig={mixConfig}
                    mixIndex={mixIndex}
                    eventUid={eventUid}
                  />
                )}
                {mixConfig.type === "spalk" && (
                  <MixSpalkDetails
                    mixConfig={mixConfig}
                    mixIndex={mixIndex}
                    eventUid={eventUid}
                  />
                )}
                {mixConfig.type === "tvu_mediahub" && (
                  <MixTvuMediaHubDetails
                    mixConfig={mixConfig}
                    mixIndex={mixIndex}
                    eventUid={eventUid}
                  />
                )}
              </Box>
            </Paper>
          </React.Fragment>
        ))
      ) : (
        <Nirvana></Nirvana>
      )}
    </Box>
  );
}

export default MixConfigs;
