import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  Card,
  Box,
  Typography,
  IconButton,
  TablePagination,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { MyContext } from "../../context/Context";
import ArchivedScheduleRow from "./ArchivedScheduleRow"; // Import the row component
import DataObjectIcon from "@mui/icons-material/DataObject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FunctionsIcon from "@mui/icons-material/Functions";
import moment from "moment-timezone"; // For working with dates
import Description from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import Nirvana from "../Utility/Nirvana";

function ArchivedScheduleTable({ schedules, timezone }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [timeFilter, setTimeFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [taskFilter, setTaskFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [filteredSchedules, setFilteredSchedules] = useState(schedules);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("schedule_description");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const [events, setEvents] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    handleFilterChange();
    extractDistinctValues();
  }, [
    schedules,
    timeFilter,
    eventFilter,
    numberFilter,
    taskFilter,
    typeFilter,
  ]);

  const extractDistinctValues = () => {
    if (schedules && schedules.length > 0) {
      const eventSet = new Set();
      const numberSet = new Set();
      const taskSet = new Set();
      const typeSet = new Set();

      schedules.forEach((schedule) => {
        const [event, number, task, type] =
          schedule.schedule_description.split(";");

        eventSet.add(event);
        numberSet.add(number);
        taskSet.add(task);
        typeSet.add(type);
      });

      setEvents([...eventSet]);
      setNumbers([...numberSet]);
      setTasks([...taskSet]);
      setTypes([...typeSet]);
    }
  };

  // Handle filtering of schedules based on time, event, number, task, and type
  const handleFilterChange = () => {
    const filteredData = schedules.filter((schedule) => {
      const [event, number, task, type] =
        schedule.schedule_description.split(";");

      const executionTime = moment
        .tz(Number(schedule.execution_time), timezone)
        .format("MM/DD/YYYY HH:mm");

      const matchesTime =
        timeFilter === "" || executionTime.includes(timeFilter);

      const matchesEvent = eventFilter
        ? event.toLowerCase() === eventFilter.toLowerCase()
        : true;
      const matchesNumber = numberFilter
        ? number.toLowerCase() === numberFilter.toLowerCase()
        : true;
      const matchesTask = taskFilter
        ? task.toLowerCase() === taskFilter.toLowerCase()
        : true;
      const matchesType = typeFilter
        ? type.toLowerCase() === typeFilter.toLowerCase()
        : true;

      return (
        matchesTime &&
        matchesEvent &&
        matchesNumber &&
        matchesTask &&
        matchesType
      );
    });

    setFilteredSchedules(filteredData);
  };

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = filteredSchedules
    ? [...filteredSchedules].sort((a, b) => {
        const valueA =
          orderBy === "execution_time"
            ? moment.tz(Number(a[orderBy]), timezone).format("MM/DD/YYYY HH:mm")
            : a[orderBy];
        const valueB =
          orderBy === "execution_time"
            ? moment.tz(Number(b[orderBy]), timezone).format("MM/DD/YYYY HH:mm")
            : b[orderBy];

        return (
          (orderDirection === "asc" ? 1 : -1) *
          (valueA < valueB ? -1 : valueA > valueB ? 1 : 0)
        );
      })
    : [];

  // Paginate the sorted data
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleDeleteSchedules = async (schedules, message) => {
    try {
      await requestConfirm(message);
      let object = {
        command: "command_any_table",
        obj: {
          command: "delete_items",
          item: schedules,
          table_name: "schedules_archive",
        },
      };
      let url = process.env.REACT_APP_DATABASE_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(object),
        },
        onSuccessfulModification
      );
      return result;
    } catch (error) {
      console.log(error);
      console.log("Delete action cancelled by user");
    }
  };

  const clearAllFilters = () => {
    setTimeFilter("");
    setEventFilter("");
    setNumberFilter("");
    setTaskFilter("");
    setTypeFilter("");
    setFilteredSchedules(schedules);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset page to zero when rows per page changes
  };

  return (
    <Card
      style={{
        minHeight: "120vh",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div style={{ padding: "15px" }}>
        {ConfirmDialog}

        {/* Filters */}
        <Box mb={2} display="flex" gap={2} flexWrap="wrap">
          {/* Filter by Time */}
          <TextField
            style={{ minWidth: "200px" }}
            label="Filter by Time"
            variant="outlined"
            value={timeFilter}
            onChange={(e) => {
              setTimeFilter(e.target.value);
              handleFilterChange();
            }}
            placeholder="(MM/DD/YYYY HH:mm)"
          />

          {/* Filter by Event */}
          <FormControl variant="outlined" style={{ minWidth: 220 }}>
            <InputLabel>Event</InputLabel>
            <Select
              value={eventFilter}
              onChange={(e) => setEventFilter(e.target.value)}
              label="Event"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {events.map((event) => (
                <MenuItem key={event} value={event}>
                  {event}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Filter by Number */}
          <FormControl variant="outlined" style={{ minWidth: 220 }}>
            <InputLabel>Number</InputLabel>
            <Select
              value={numberFilter}
              onChange={(e) => setNumberFilter(e.target.value)}
              label="Number"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {numbers.map((number) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Filter by Task */}
          <FormControl variant="outlined" style={{ minWidth: 220 }}>
            <InputLabel>Task</InputLabel>
            <Select
              value={taskFilter}
              onChange={(e) => setTaskFilter(e.target.value)}
              label="Task"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {tasks.map((task) => (
                <MenuItem key={task} value={task}>
                  {task}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Filter by Type */}
          <FormControl variant="outlined" style={{ minWidth: 220 }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              label="Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {types.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title="Clear Filters">
            <IconButton onClick={() => clearAllFilters()} aria-label="clear">
              <FilterAltOffIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <TableContainer component={Card}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table aria-label="archive table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "schedule_description"}
                    direction={
                      orderBy === "schedule_description"
                        ? orderDirection
                        : "asc"
                    }
                    onClick={() => handleRequestSort("schedule_description")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Description />
                      <Typography variant="body1">
                        <strong>Schedule</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "execution_time"}
                    direction={
                      orderBy === "execution_time" ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort("execution_time")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon />
                      <Typography variant="body1">
                        <strong>Time</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={orderBy === "id" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("id")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <FunctionsIcon />
                      <Typography variant="body1">
                        <strong>Id</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell> */}
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "input"}
                    direction={orderBy === "input" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("input")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <DataObjectIcon />
                      <Typography variant="body1">
                        <strong>Input</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "response"}
                    direction={orderBy === "response" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("response")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <DataObjectIcon />
                      <Typography variant="body1">
                        <strong>Response</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="right" gap={1}>
                    <Tooltip title="Delete filtered schedules">
                      <Button
                        disabled={schedules.length === 0}
                        onClick={() =>
                          handleDeleteSchedules(
                            filteredSchedules,
                            "Are you sure you want to delete the filtered schedules? (Including additional pages)"
                          )
                        }
                        aria-label="delete all"
                      >
                        <DeleteIcon className="icon" color="secondary" />
                        <Typography variant="body1" pl="5px"></Typography>
                      </Button>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Nirvana></Nirvana>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((schedule, index) => (
                  <ArchivedScheduleRow
                    key={schedule.uid}
                    schedule={schedule}
                    timezone={timezone}
                    rowIndex={index}
                    onDelete={handleDeleteSchedules}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
}

export default ArchivedScheduleTable;
