import React, { useState, useContext, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button } from "@mui/material";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Gateway from "./Gateway";
import Streamhub from "./Streamhub";

export default function Streamhubs() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleStart = async (streamhubs) => {
    try {
      await requestConfirm("Are you sure you?");
      let url = process.env.REACT_APP_START_STREAMHUBS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            streamhubs: streamhubs,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStop = async (streamhubs) => {
    try {
      await requestConfirm("Are you sure?");
      let url = process.env.REACT_APP_STOP_STREAMHUBS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            streamhubs: streamhubs,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      {ConfirmDialog}
      {/* <p>{JSON.stringify(state.database.streamhubs)}</p> */}
      {state.database.streamhubs ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleStart(state.database.streamhubs)} // Using AWS Instance ID for action
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "0px",
              marginLeft: "10px",
            }}
          >
            Start All
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleStop(state.database.streamhubs)} // Using AWS Instance ID for action
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
          >
            Stop All
          </Button>
        </Box>
      ) : null}

      {state.database.streamhubs?.map((streamhub, index) => {
        return (
          <Streamhub
            streamhub={streamhub}
            index={index}
            key={streamhub["host"]}
          ></Streamhub>
        );
      })}
    </div>
  );
}
