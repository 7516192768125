import React, { useContext } from "react";
import { MyContext } from "../../context/Context";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import constants from "../../context/constants.json"
function TimezoneSelector() {
  const { state, dispatch } = useContext(MyContext);

  const handleChange = (event) => {
    dispatch({ type: "SET_TIMEZONE", payload: event.target.value });
  };


  return (
    <div>
      <FormControl style={{ minWidth: "100%" }}>
        <InputLabel id="timezone-select-label">Timezone</InputLabel>
        <Select
          labelId="timezone-select-label"
          id="timezone-select"
          value={state.settings.timezone}
          label="Timezone"
          onChange={handleChange}
        >
          {constants.timezones.map((timezone) => (
            <MenuItem key={timezone} value={timezone}>
              {timezone}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default TimezoneSelector;
