import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import JsonImporter from "../../Utility/JsonImporter";
const AddGatewayProfileForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);

  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    routes: [],
    rundown: [],
  });

  const [selectedTakerProfile, setSelectedTakerProfile] = useState("");
  const [selectedAbility, setSelectedAbility] = useState("");
  const [error, setError] = useState("");

  const [routeInput, setRouteInput] = useState({
    address: "0.0.0.0",
    latency: "300",
    mode: "listener",
    passphrase: "hbmc-mix-42",
    route_name: "clean",
    route_uid: uuidv4(),
    taker_profile_uid: "",
  });

  const handleRemoveRoute = (route_to_remove) => {
    setFormData({
      ...formData,
      routes: formData.routes.filter((route) => route !== route_to_remove),
    });
  };

  const handleTakerProfileChange = (event) => {
    let profile = event.target.value;
    setRouteInput((prev) => ({
      ...prev,
      taker_profile_uid: profile.uid,
    }));
    console.log(profile);
    setSelectedTakerProfile(profile);
  };
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRouteChange = (event) => {
    const { name, value } = event.target;
    setRouteInput({ ...routeInput, [name]: value });
  };

  const handleRouteModeChange = (event) => {
    setRouteInput({ ...routeInput, mode: event.target.value });
  };

  // And a function to add the route to the formData:
  const handleAddRoute = () => {
    if (routeInput.taker_profile_uid != "") {
      console.log(routeInput);
      setFormData({ ...formData, routes: [...formData.routes, routeInput] });
      setRouteInput({
        address: "0.0.0.0",
        latency: "300",
        mode: "listener",
        passphrase: "hbmc-mix-42",
        route_name: "clean",
        route_uid: uuidv4(),
        taker_profile_uid: selectedTakerProfile.uid,
      });
    }
  };

  const handleAddRundownTask = (taskToAdd) => {
    // Check if the rundown already contains a task with the same uid
    // const isAlreadyAdded = formData.rundown.some(
    //   (task) => task.uid === taskToAdd.uid
    // );

    // If not already added, append the new task to the rundown array
    setFormData((prevFormData) => ({
      ...prevFormData,
      rundown: [...prevFormData.rundown, taskToAdd],
    }));
  };

  const handleClose = () => {
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };

  const handleSubmit = () => {
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && formData[key] === "") {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });

    if (isValid) {
      onClose();
      setError("");
      onAdd(formData);
      setFormData({
        uid: uuidv4(),
        alias: "",
        routes: [],
        rundown: [],
      });
      setRouteInput({
        address: "0.0.0.0",
        latency: "300",
        mode: "listener",
        passphrase: "hbmc-mix-42",
        route_name: "",
        route_uid: uuidv4(),
      });
      console.log(formData);
    }
  };
  const handleRemoveRundownTask = (index) => {
    console.log(index);
    setFormData({
      ...formData,
      rundown: formData.rundown.filter((_, i) => i !== index),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {" "}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1">Add Gateway Profile Item</Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>{" "}
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          margin="normal"
          name="uid"
          label="Uid"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={(e) => handleChange(e)}
        />
        <FormControl fullWidth margin="normal">
          <FormControl fullWidth margin="normal">
            <Typography>Create Route/Feed</Typography>
            <TextField
              margin="normal"
              name="route_name"
              label="route_name"
              type="text"
              fullWidth
              variant="outlined"
              value={routeInput.route_name}
              onChange={handleRouteChange}
            />
            <TextField
              margin="normal"
              name="route_uid"
              label="route_uid"
              type="text"
              fullWidth
              variant="outlined"
              value={routeInput.route_uid}
              onChange={handleRouteChange}
            />
            <TextField
              margin="normal"
              name="passphrase"
              label="Passphrase"
              type="text"
              fullWidth
              variant="outlined"
              value={routeInput.passphrase}
              onChange={handleRouteChange}
            />
            <TextField
              margin="normal"
              name="latency"
              label="latency"
              type="text"
              fullWidth
              variant="outlined"
              value={routeInput.latency}
              onChange={handleRouteChange}
            />
            <TextField
              margin="normal"
              name="address"
              label="address"
              type="text"
              fullWidth
              variant="outlined"
              value={routeInput.address}
              onChange={handleRouteChange}
            />{" "}
            <FormControl fullWidth margin="normal">
              <InputLabel id="mode-select-label">Mode </InputLabel>
              <Select
                key={"mode"}
                labelId="mode-select-label"
                value={routeInput.mode}
                onChange={handleRouteModeChange}
                label="Mode"
              >
                <MenuItem key={"caller"} value={"caller"}>
                  caller
                </MenuItem>
                <MenuItem key={"listener"} value={"listener"}>
                  listener{" "}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="taker-select-label">Taker Profile </InputLabel>
              <Select
                labelId="taker-select-label"
                value={selectedTakerProfile}
                onChange={handleTakerProfileChange}
                label="Taker Profile"
              >
                {state.database.taker_profiles
                  .slice() // Create a shallow copy of the array to prevent modifying the original array
                  .sort((a, b) => {
                    // Use localeCompare for a safe alphabetical sort that handles lowercase, uppercase and international characters consistently
                    const nameA = a.alias || a.uid; // Fallback to uid if alias is not present
                    const nameB = b.alias || b.uid; // Fallback to uid if alias is not present
                    return nameA.localeCompare(nameB);
                  })
                  .map((profile) => (
                    <MenuItem key={profile.uid} value={profile}>
                      {profile.alias}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={() => {
                handleAddRoute(routeInput);
              }}
              style={{ marginTop: "10px" }}
              disabled={
                formData.routes.some(
                  (item) => item.route_name === routeInput.route_name
                ) || selectedTakerProfile == ""
              }
            >
              Add Route
            </Button>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                marginTop: "10px",
              }}
            >
              {formData.routes.map((route, index) => (
                <Chip
                  key={route.route_name + index}
                  label={
                    route.route_name +
                    " : " +
                    (state.database[`taker_profiles`].find(
                      (profile) => profile.uid === route.taker_profile_uid
                    )?.alias || "Profile Not Found")
                  }
                  onDelete={() => {
                    handleRemoveRoute(route);
                  }}
                  color="primary"
                />
              ))}
            </Box>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="tvu-abilities-select-label">
              Gateway Rundown Tasks{" "}
            </InputLabel>
            <Select
              labelId="tvu-abilities-select-label"
              value={selectedAbility}
              onChange={(e) => setSelectedAbility(e.target.value)}
              label="Gateway Rundwon Tasks"
            >
              {state.database.gateways_abilities.map((ability) => (
                <MenuItem key={ability.uid} value={ability}>
                  {ability.alias}
                </MenuItem>
              ))}
            </Select>
            {selectedAbility ? (
              <TextField
                autoFocus
                margin="normal"
                name="alias"
                label="execution_offset"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedAbility.execution_offset}
                onChange={(e) => {
                  setSelectedAbility({
                    ...selectedAbility,
                    execution_offset: e.target.value,
                  });
                }}
              />
            ) : null}
            <Button
              variant="contained"
              onClick={() => {
                handleAddRundownTask(selectedAbility);
              }}
              style={{ marginTop: "10px" }}
              disabled={selectedAbility == ""}
            >
              Add Rundown Task
            </Button>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                marginTop: "40px",
              }}
            >
              {formData.rundown.map((ability, index) => (
                <Chip
                  key={ability.uid + index}
                  label={ability.alias + " : " + ability.execution_offset}
                  onDelete={() => {
                    handleRemoveRundownTask(index);
                  }}
                  color="primary"
                />
              ))}
            </Box>
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={formData.routes.length == 0 || formData.rundown.length == 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGatewayProfileForm;
