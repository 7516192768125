import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css"; // Make sure this path is correct
import LogoutIcon from "@mui/icons-material/Logout";
import SyncIcon from "@mui/icons-material/Sync";
import { MyContext } from "../context/Context";
import StorageIcon from "@mui/icons-material/Storage";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import SportsIcon from "@mui/icons-material/Sports";
import ConstructionIcon from "@mui/icons-material/Construction";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
function NavBar(props) {
  const { state, onSuccessfulModification } = useContext(MyContext);

  return (
    <div style={{ marginBottom: "20px" }}>
      <nav>
        <NavLink
          to="/events"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <SportsIcon />
            <span>Events</span>
          </div>
        </NavLink>
        <NavLink
          to="/schedules"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <CalendarMonthIcon />
            <span>Schedules</span>
          </div>
        </NavLink>
        <NavLink
          to="/database"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <StorageIcon />
            <span>Database</span>
          </div>
        </NavLink>
        <NavLink
          to="/infrastructure"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <ConstructionIcon />
            <span>Infrastructure</span>
          </div>
        </NavLink>
        {/* <NavLink
          to="/monitoring"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <MonitorHeartIcon />
            <span>Monitoring</span>
          </div>
        </NavLink> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            marginRight: "20px",
            marginLeft: "auto",
          }}
        >
          {" "}
          <AccessTimeIcon style={{ color: "white" }}></AccessTimeIcon>
          <p style={{ color: "white" }}>{state.settings.timezone}</p>
        </div>{" "}
        <NavLink
          to="/flow"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <AccountTreeIcon />
            <span>Flow (Beta)</span>
          </div>
        </NavLink>
        <NavLink
          to="/settings"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <SettingsIcon />
            <span>Settings</span>
          </div>
        </NavLink>
        <button
          className="button"
          onClick={onSuccessfulModification}
          style={{ backgroundColor: "#1976d2" }}
        >
          <SyncIcon />
        </button>
        <button className="button" onClick={props.signOut}>
          <LogoutIcon />
        </button>
      </nav>
      {state.activeRequests > 0 ? (
        <Box sx={{ width: "100%", height: "5px" }}>
          <LinearProgress />
        </Box>
      ) : (
        <div style={{ height: "5px" }}></div> // Empty space when there are no active requests
      )}
    </div>
  );
}

export default NavBar;
