import React, { useState, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { MyContext } from "../../context/Context";
import TableDetails from "./TableDetails";
import AddItemFormRouting from "./AddForms/ItemAddFormRouting";
import schemas from "./schemas.json"; // Adjust path as necessary
import DataObjectIcon from "@mui/icons-material/DataObject";
import AddIcon from "@mui/icons-material/Add";
import TocIcon from "@mui/icons-material/Toc";
import { useTheme } from "@mui/material/styles";
import SyncIcon from "@mui/icons-material/Sync";
import { Tooltip } from "@mui/material";

export default function TableAccordion({ tableName, index }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [addItemOpen, setAddItemOpen] = useState(false);
  const theme = useTheme();
  const [tableKey, setTableKey] = useState(0);
  const currentSchema = schemas.tables[tableName]?.item_schema;

  const triggerRemount = () => {
    // Update the key to trigger a remount
    setTableKey((prevKey) => prevKey + 1);
  };

  const handleAddItemOpen = () => {
    setAddItemOpen(true);
  };

  const handleAddItemClose = () => {
    setAddItemOpen(false);
  };

  function formatTableName(tableName) {
    // Split the table name by underscores
    const parts = tableName.split("_");
    // Capitalize the first letter of each part
    const capitalizedParts = parts.map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1)
    );
    // Join the parts back together with spaces
    const formattedName = capitalizedParts.join(" ");
    return formattedName;
  }

  return (
    <div>
      <Accordion
        style={{
          backgroundColor:
            index % 2 === 0
              ? theme.palette.mode === "dark"
                ? "#2e2e2e"
                : "#f1f1f1"
              : "",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <TocIcon style={{ marginRight: "10px" }} color="primary"></TocIcon>
          <strong>{formatTableName(tableName)}</strong>
        </AccordionSummary>
        <AccordionActions style={{ padding: "0px" }}>
          <Tooltip title="Add Item">
            <Button
              onClick={handleAddItemOpen}
              variant="contained"
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginRight: "50px",
                minWidth: "150px",
              }}
            >
              {" "}
              <AddIcon></AddIcon>{" "}
            </Button>
          </Tooltip>
          <Tooltip title="Reset">
            <Button
              onClick={triggerRemount}
              variant="contained"
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginRight: "50px",
                minWidth: "50px",
              }}
            >
              <SyncIcon />
            </Button>
          </Tooltip>
        </AccordionActions>
        <AccordionDetails style={{ padding: "0px" }}>
          <TableDetails tableName={tableName} key={tableKey} />
        </AccordionDetails>
      </Accordion>
      {currentSchema && (
        <AddItemFormRouting
          open={addItemOpen}
          onClose={handleAddItemClose}
          schema={currentSchema}
          tableName={tableName}
        />
      )}
    </div>
  );
}
