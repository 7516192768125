import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function RouteSource({ source }) {
  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h6" gutterBottom>
        Source: {source.name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography>Address: {source.address}</Typography>
          <Typography>Port: {source.port}</Typography>
          <Typography>Mode: {source.mode}</Typography>
          <Typography>Protocol: {source.protocol}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>State: {source.state}</Typography>
          <Typography>Status: {source.summaryStatusDetails}</Typography>
          <Typography>SRT PassPhrase: {source.srtPassPhrase}</Typography>
          <Typography>SRT Crypto Mode: {source.srtCryptoMode}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
