import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../../context/Context";
import Gateways from "./Gateways";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import Typography from "@mui/material/Typography";
import Streamhubs from "./Streamhubs";
import Elixir from "./Elixir";
import ContributionSimulator from "./ContributionSimulator";
import S3Explorer from "./S3Explorer";
import FolderIcon from "@mui/icons-material/Folder";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ScienceIcon from "@mui/icons-material/Science";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import TransformIcon from "@mui/icons-material/Transform";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import Cloudcutters from "./Cloudcutters";
import Promexs from "./Promexs";
import Observers from "./Observers";
import Nablets from "./Nablets";
import LoaderSkeleton from "../Utility/LoaderSkeleton";

export default function Infrastructure() {
  const { state, onSuccessfulModification } = useContext(MyContext);

  return (
    <div>
      {Object.keys(state.status).length !== 0 ? (
        <div style={{ margin: "5px" }}>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TransformIcon className="icon" color="primary" />
              <Typography pl={1}>SRT Gateways</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Gateways></Gateways>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <RadioButtonCheckedIcon className="icon" color="primary" />
              <Typography pl={1}>Streamhubs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Streamhubs> </Streamhubs>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ScienceIcon className="icon" color="primary" />
              <Typography pl={1}>Elixir</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Elixir></Elixir>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ContentCutIcon className="icon" color="primary" />
              <Typography pl={1}>Cloudcutters (Near Live Editing)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Cloudcutters />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <RadioButtonCheckedIcon className="icon" color="primary" />
              <Typography pl={1}>Nablet Recorders</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Nablets></Nablets>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <MonitorHeartIcon className="icon" color="primary" />
              <Typography pl={1}>Observers (Grafana & Prometheus)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Observers></Observers>
            </AccordionDetails>
          </Accordion>{" "}
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <NotificationsActiveIcon className="icon" color="primary" />
              <Typography pl={1}>Promexs (Prometheus Exporter)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Promexs></Promexs>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FolderIcon className="icon" color="primary" />
              <Typography pl={1}>Storage (Tournament assets etc.)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <S3Explorer initialBucketName={"hbmc-director"}></S3Explorer>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <VideoCallIcon className="icon" color="primary" />
              <Typography pl={1}>Contribution Simulator</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ContributionSimulator></ContributionSimulator>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <LoaderSkeleton skeletonCount={7}></LoaderSkeleton>
      )}
    </div>
  );
}
