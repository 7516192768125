import React, { useState, useContext, useMemo, useEffect } from "react";
import { MyContext } from "../../context/Context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Typography,
  Tooltip,
  Box,
  Input,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import JSONDialog from "../Utility/JSONDialog";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useConfirm from "../Utility/useConfirm";
import moment from "moment-timezone";
import schemas from "./schemas.json";
import { useApiRequest } from "../Utility/useAPIRequest";
import { useTheme } from "@mui/material/styles";
import DeleteProgressDialog from "../Utility/DeleteProgressDialog";

export default function TableDetails({ tableName, key }) {
  const { state } = useContext(MyContext);
  const [enableEdit, setEnableEdit] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { makeApiRequest } = useApiRequest();
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const theme = useTheme();

  const [sortConfig, setSortConfig] = useState({
    key: "alias",
    direction: "ascending",
  });
  const [editedData, setEditedData] = useState({});

  // State for Deletion Progress Dialog
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);
  const [totalItemsToDelete, setTotalItemsToDelete] = useState(0);
  const [currentDeletionItem, setCurrentDeletionItem] = useState(0);

  useEffect(() => {
    setEditedData({});
    setSelectedItems([]);
  }, [key, tableName]);

  const handleSelectItem = (uid) => {
    setSelectedItems((prev) =>
      prev.includes(uid) ? prev.filter((id) => id !== uid) : [...prev, uid]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(sortedData.map((row) => row.uid));
    } else {
      setSelectedItems([]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await requestConfirm(
        "Are you sure you want to delete the selected items?"
      );
      const selectedData = state.database[tableName].filter((item) =>
        selectedItems.includes(item.uid)
      );

      setTotalItemsToDelete(selectedData.length);
      setCurrentDeletionItem(0);
      setDeletionDialogOpen(true);

      // Run all deletions in parallel and track progress
      await Promise.all(
        selectedData.map(async (item, index) => {
          await deleteItemFromTable(item); // Delete item
          setCurrentDeletionItem((prev) => prev + 1); // Update progress
        })
      );

      setSelectedItems([]);
      setDeletionDialogOpen(false);
    } catch (error) {
      console.log("Deletion cancelled by user");
      setDeletionDialogOpen(false);
    }
  };

  const deleteItemFromTable = async (item) => {
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        command: "command_any_table",
        obj: { command: "delete", item, table_name: tableName },
      }),
    };
    const { success, error } = await makeApiRequest(
      endpoint,
      options,
      tableName
    );
    if (!success) console.error("Failed to delete item:", error);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key, direction });
  };

  const formatValue = (key, value) => {
    if (schemas.tables[tableName]?.item_schema[key]?.format === "timestamp") {
      return moment
        .tz(Number(value), state.settings.timezone)
        .format("MM/DD/YYYY HH:mm:ss");
    }
    return value;
  };

  const handleSave = async (uid) => {
    const updatedItem = {
      ...state.database[tableName].find((item) => item.uid === uid),
      ...editedData[uid],
    };

    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        command: "command_any_table",
        obj: { command: "put", item: updatedItem, table_name: tableName },
      }),
    };

    const { success, error } = await makeApiRequest(
      endpoint,
      options,
      tableName
    );
    if (success) {
      setEditedData((prev) => {
        const newData = { ...prev };
        delete newData[uid];
        return newData;
      });
    } else {
      console.error("Failed to save data:", error);
    }
  };

  const handleDownload = (item) => {
    const jsonString = JSON.stringify(item, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = item.alias + ".json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const headerKeys = useMemo(() => {
    const keys = schemas.tables[tableName]?.item_schema
      ? Object.keys(schemas.tables[tableName].item_schema)
      : [];
    return keys.includes("uid")
      ? ["uid", ...keys.filter((k) => k !== "uid")]
      : keys;
  }, [schemas.tables, tableName]);

  const sortedData = useMemo(() => {
    const sortableItems = [...(state.database[tableName] || [])];
    if (sortConfig.key) {
      sortableItems.sort((a, b) =>
        a[sortConfig.key] < b[sortConfig.key]
          ? sortConfig.direction === "ascending"
            ? -1
            : 1
          : a[sortConfig.key] > b[sortConfig.key]
          ? sortConfig.direction === "ascending"
            ? 1
            : -1
          : 0
      );
    }
    return sortableItems.map((item) => ({
      ...item,
      ...editedData[item.uid],
    }));
  }, [tableName, sortConfig, key, state.database[tableName], editedData]);

  return (
    <div style={{ marginLeft: "5px", marginRight: "5px" }}>
      {ConfirmDialog}
      <TableContainer
        component={Paper}
        style={{ width: "100%", marginBottom: "100px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headerKeys.map((key) => (
                <TableCell
                  key={key}
                  align="left"
                  onClick={() => handleSort(key)}
                  style={{ cursor: "pointer" }}
                >
                  <Box display="flex" gap="10px" justifyContent={"left"}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {key
                        .replace(/_/g, " ")
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Typography>
                    {sortConfig.key === key &&
                      (sortConfig.direction === "ascending" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      ))}
                  </Box>
                </TableCell>
              ))}
              <TableCell align="right">
                <IconButton onClick={() => setEnableEdit(!enableEdit)}>
                  {enableEdit ? (
                    <Tooltip title="Disable Edit">
                      <EditOffIcon color="primary" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Enable Edit">
                      <EditIcon />
                    </Tooltip>
                  )}
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <Box display="flex" gap="5px" justifyContent={"center"}>
                  <Checkbox
                    color="secondary"
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < sortedData.length
                    }
                    checked={selectedItems.length === sortedData.length}
                    onChange={handleSelectAll}
                  />
                  <IconButton
                    onClick={handleDeleteSelected}
                    disabled={selectedItems.length === 0}
                  >
                    <Tooltip title="Delete Selected">
                      <DeleteIcon
                        color={selectedItems.length > 0 ? "secondary" : ""}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell align="right">
                <IconButton disabled>
                  <FileDownloadIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow
                key={row.uid}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? theme.palette.mode === "dark"
                        ? "#2e2e2e"
                        : "#f1f1f1"
                      : "",
                }}
              >
                {headerKeys.map((key) => (
                  <TableCell key={key} align="left">
                    {typeof row[key] === "object" ? (
                      <JSONDialog
                        key={row.uid}
                        input_json={row[key]}
                        name={key}
                        enableEdit={enableEdit}
                        handleNewJSON={(json) =>
                          setEditedData((prev) => ({
                            ...prev,
                            [row.uid]: {
                              ...(prev[row.uid] || {}),
                              [key]: json,
                            },
                          }))
                        }
                      />
                    ) : enableEdit ? (
                      <Input
                        type="text"
                        value={editedData[row.uid]?.[key] || row[key]}
                        onChange={(e) =>
                          setEditedData((prev) => ({
                            ...prev,
                            [row.uid]: {
                              ...prev[row.uid],
                              [key]: e.target.value,
                            },
                          }))
                        }
                        disabled={key === "uid"}
                      />
                    ) : (
                      <Typography
                        style={
                          key.includes("uid")
                            ? { fontSize: "10px", color: "grey" }
                            : {}
                        }
                      >
                        {formatValue(key, row[key])}
                      </Typography>
                    )}
                  </TableCell>
                ))}
                <TableCell align="right" style={{ width: "50px" }}>
                  <Tooltip title="Save Changes to DB">
                    <IconButton
                      disabled={!enableEdit || !editedData[row.uid]}
                      onClick={() => handleSave(row.uid)}
                      size="small"
                      color={enableEdit || editedData[row.uid] ? "primary" : ""}
                    >
                      <SaveAsIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center" style={{ width: "50px" }}>
                  <Checkbox
                    checked={selectedItems.includes(row.uid)}
                    onChange={() => handleSelectItem(row.uid)}
                    color="secondary"
                  />
                </TableCell>
                <TableCell align="right" style={{ width: "50px" }}>
                  <Tooltip title="Download JSON">
                    <IconButton
                      onClick={() => handleDownload(row)}
                      size="small"
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteProgressDialog
        open={deletionDialogOpen}
        totalItems={totalItemsToDelete}
        currentItem={currentDeletionItem}
        onClose={() => setDeletionDialogOpen(false)}
      />
    </div>
  );
}
