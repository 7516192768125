import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { MyContext } from "../context/Context";

export default function Footer(userEmail) {
  const { state, onSuccessfulModification } = useContext(MyContext);

  return (
    <div className="footer">
      <Box
        display={"flex"}
        gap={"5px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography style={{ fontSize: "10px" }}>Powered by</Typography>
        <img
          src={state.settings.darkMode ? "./hbs_white.png" : "./hbs_blue.png"}
          style={{ height: "15px" }}
        ></img>
      </Box>

      <Typography style={{ fontSize: "10px", color: "grey" }}>
        {process.env.REACT_APP_VERSION} - {userEmail.userEmail}
      </Typography>
    </div>
  );
}
