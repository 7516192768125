import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import ListItemText from "@mui/material/ListItemText";
import CustomDateTimePicker from "../../Utility/CustomDateTimePicker";
import Box from "@mui/material/Box";
import moment from "moment-timezone";
import SportsIcon from "@mui/icons-material/Sports";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JSONDialog from "../../Utility/JSONDialog";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Card } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import JsonImporter from "../../Utility/JsonImporter";

const AddEventForm = ({ open, onClose, onAdd, schema, tableName }) => {
  // Function to get the current timestamp and add hours to it
  const getTimestampWithOffset = (hoursOffset) => {
    const currentTime = new Date();
    return currentTime.setHours(currentTime.getHours() + hoursOffset);
  };

  const { state } = useContext(MyContext);
  const [formData, setFormData] = useState(() => {
    const initialData = { uid: uuidv4() };
    Object.keys(schema).forEach((key) => {
      if (key !== "uid") {
        initialData[key] = schema[key].type === "array" ? [] : "";
      }
      if (key === "config") {
        initialData[key] = {};
      }
      if (key === "start_time") {
        initialData[key] = getTimestampWithOffset(2);
      }
      if (key === "end_time") {
        initialData[key] = getTimestampWithOffset(6);
      }
    });
    return initialData;
  });

  const [error, setError] = useState("");

  const handleChange = (key, value) => {
    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData, [key]: value };

      if (key === "workflow_profile_uid") {
        updatedFormData.hekma_archive_ids = [];
      }

      if (key === "start_time" && prevFormData.end_time == "") {
        updatedFormData.end_time = value + 300000;
      }

      return updatedFormData;
    });
  };

  const handleChangeInHBMCArchive = (value, index) => {
    let newArray = formData["hekma_archive_ids"];
    newArray[index] = { hekma_archive_id: value, recording_index: index };
    setFormData({ ...formData, ["hekma_archive_ids"]: newArray });
  };

  const handleClose = () => {
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };

  const handleSubmit = () => {
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && formData[key] === "") {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });

    if (isValid) {
      onClose();
      setError("");
      onAdd(formData);
      setFormData((prev) => ({
        ...prev,
        uid: uuidv4(),
      }));
    }
  };

  const formatTimeValue = (key, value) => {
    const formattedTime = moment
      .tz(Number(value), state.settings.timezone)
      .format("MM/DD/YYYY HH:mm:ss");
    const timestamp = value;
    return (
      <>
        <div style={{ fontSize: "15px" }}>{formattedTime}</div>
        {/* <div style={{ fontSize: "12px", color: "gray" }}>{timestamp}</div> */}
      </>
    );
  };

  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <SportsIcon />
            <Typography variant="body1">
              Add New {tableName.replace(/_/g, " ")} Item
            </Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        {Object.keys(schema).map((key) => {
          const field = schema[key];
          if (field.tableReference) {
            const referenceData = state.database[field.tableReference];
            return (
              <FormControl fullWidth margin="dense" key={key}>
                <InputLabel>
                  {(key.charAt(0).toUpperCase() + key.slice(1)).replace(
                    "_uid",
                    ""
                  )}
                </InputLabel>
                <Select
                  value={formData[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                  label={(key.charAt(0).toUpperCase() + key.slice(1)).replace(
                    "_uid",
                    ""
                  )}
                >
                  {referenceData &&
                    referenceData
                      .slice()
                      .sort((a, b) => {
                        const nameA = a.alias || a.uid;
                        const nameB = b.alias || b.uid;
                        return nameA.localeCompare(nameB);
                      })
                      .map((item) => (
                        <MenuItem key={item.uid} value={item.uid}>
                          <ListItemText
                            primary={item.alias || item.uid}
                            // secondary={item.uid}
                          />{" "}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            );
          } else if (field.format === "timestamp") {
            return (
              <div key={key}>
                <Box display="flex" alignItems="center" gap={1}>
                  <p>{formatTimeValue(key, formData[key])}</p>
                  <TextField
                    margin="dense"
                    key={key}
                    label={key
                      .charAt(0)
                      .toUpperCase()
                      .concat(key.slice(1).replace(/_/g, " "))}
                    value={formData[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    required={field.required}
                  />
                  <CustomDateTimePicker
                    key={key + "_time_picker"}
                    label={
                      key.charAt(0).toUpperCase() +
                      key.slice(1).replace(/_/g, " ")
                    }
                    onConfirm={(dateTime) => handleChange(key, dateTime)}
                  />
                </Box>
              </div>
            );
          } else if (key === "hekma_archive_ids") {
            return <div key={key}> </div>;
          } else if (key === "home_team" || key === "away_team") {
            return (
              <FormControl fullWidth margin="dense" key={key}>
                <InputLabel>
                  {key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}
                </InputLabel>
                <Select
                  value={formData[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  renderValue={(selected) => {
                    const team = state.database.tournaments
                      ?.find(
                        (profile) => profile.uid === formData.tournament_uid
                      )
                      ?.teams.find((team) => team.uid === selected);
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ListItemText
                          primary={team.alias}
                          secondary={`${team.short_code} ${team.uid}`}
                          style={{ marginRight: "8px" }}
                        />
                        <img
                          src={team.logo_url}
                          style={{ maxWidth: "20px", marginRight: "10px" }}
                          alt="Team Logo"
                        />
                        <BookmarkIcon style={{ color: team.primary_color }} />
                      </div>
                    );
                  }}
                >
                  {state.database.tournaments
                    ?.find((profile) => profile.uid === formData.tournament_uid)
                    ?.teams.map((team) => (
                      <MenuItem
                        key={team.uid}
                        value={team.uid}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <ListItemText
                          primary={team.alias}
                          secondary={team.short_code}
                          style={{ marginRight: "8px" }}
                        />
                        <img
                          src={team.logo_url}
                          style={{ maxWidth: "20px", marginRight: "10px" }}
                          alt="Team Logo"
                        />
                        <BookmarkIcon style={{ color: team.primary_color }} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            );
          } else if (key === "stage") {
            return (
              <FormControl fullWidth margin="dense" key={key}>
                <InputLabel>
                  {key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}
                </InputLabel>
                <Select
                  value={formData[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                >
                  {state.database.tournaments
                    ?.find((profile) => profile.uid === formData.tournament_uid)
                    ?.stages.map((stage) => (
                      <MenuItem key={stage} value={stage}>
                        <ListItemText primary={stage} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            );
          } else if (key !== "config") {
            return (
              <TextField
                fullWidth
                margin="dense"
                key={key}
                label={key
                  .charAt(0)
                  .toUpperCase()
                  .concat(key.slice(1).replace(/_/g, " "))}
                value={formData[key]}
                onChange={(e) => handleChange(key, e.target.value)}
                required={field.required}
                inputProps={{
                  maxLength:
                    key === "home_team" || key === "away_team" ? 6 : 1000,
                }}
              />
            );
          }
          return null;
        })}

        {state.database.workflow_profiles.map((item) => {
          if (item.uid === formData.workflow_profile_uid) {
            return (
              <Card style={{ marginTop: "10px" }} key={item.uid}>
                <Box
                  style={{ padding: "10px" }}
                  display="flex"
                  alignContent={"center"}
                  gap={"10px"}
                >
                  <InfoIcon></InfoIcon>
                  <Typography>Workflow Info</Typography>
                </Box>
                <Box
                  key={item.uid}
                  display="flex"
                  padding="20px"
                  gap="10px"
                  marginBottom="20px"
                  flexDirection={"column"}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bolder", margin: "5px" }}>
                      Exchange
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Alias</TableCell>
                            <TableCell>Feeds</TableCell>

                            <TableCell>Profile</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.exchange.map((exchange, index) => (
                            <TableRow key={index}>
                              <TableCell>{exchange.type}</TableCell>
                              <TableCell>{exchange.alias}</TableCell>
                              <TableCell>
                                {state.database[`${exchange.type}_profiles`]
                                  ?.find(
                                    (profile) =>
                                      profile.uid === exchange.profile_uid
                                  )
                                  ?.routes.map((route) => route.route_name)
                                  .join(", ")}
                              </TableCell>

                              <TableCell>
                                <JSONDialog
                                  input_json={state.database[
                                    `${exchange.type}_profiles`
                                  ]?.find(
                                    (profile) =>
                                      profile.uid === exchange.profile_uid
                                  )}
                                  name={exchange.alias}
                                  enableEdit={false}
                                  handleNewJSON={() => {}}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <Box>
                    <Typography style={{ fontWeight: "bolder", margin: "5px" }}>
                      Mix
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Alias</TableCell>
                            <TableCell>Source Route</TableCell>
                            <TableCell>Destination Route</TableCell>
                            <TableCell>Profile</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.mix.map((mix, index) => (
                            <TableRow key={index}>
                              <TableCell>{mix.type}</TableCell>
                              <TableCell>{mix.alias}</TableCell>
                              <TableCell>{mix.source_route_name}</TableCell>
                              <TableCell>{mix.route_name}</TableCell>
                              <TableCell>
                                <JSONDialog
                                  input_json={state.database[
                                    `${mix.type}_profiles`
                                  ]?.find(
                                    (profile) => profile.uid === mix.profile_uid
                                  )}
                                  name={mix.alias}
                                  enableEdit={false}
                                  handleNewJSON={() => {}}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  {item.record.length > 0 && (
                    <Box>
                      <Typography
                        style={{ fontWeight: "bolder", margin: "5px" }}
                      >
                        Record
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Alias</TableCell>
                              <TableCell>Feed</TableCell>
                              <TableCell>Profile</TableCell>
                              <TableCell>Params</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.record.map((recording, index) => (
                              <TableRow key={index}>
                                <TableCell>{recording.type}</TableCell>
                                <TableCell>{recording.alias}</TableCell>
                                <TableCell>
                                  {recording.source_route_name}
                                </TableCell>
                                <TableCell>
                                  <JSONDialog
                                    input_json={state.database[
                                      `${recording.type}_profiles`
                                    ]?.find(
                                      (profile) =>
                                        profile.uid === recording.profile_uid
                                    )}
                                    name={recording.alias}
                                    enableEdit={false}
                                    handleNewJSON={() => {}}
                                  />
                                </TableCell>
                                {recording.type === "hbmc_archive" ? (
                                  <TableCell>
                                    <TextField
                                      fullWidth
                                      margin="dense"
                                      label="hbmc archive id"
                                      value={
                                        formData.hekma_archive_ids[index]
                                          ?.hekma_archive_id
                                      }
                                      onChange={(e) =>
                                        handleChangeInHBMCArchive(
                                          e.target.value,
                                          index
                                        )
                                      }
                                      required
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell></TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {item.contribute.length > 0 && (
                    <Box>
                      <Typography
                        style={{ fontWeight: "bolder", margin: "5px" }}
                      >
                        Contribute
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Alias</TableCell>
                              <TableCell>Profile</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.contribute.map((contribute, index) => (
                              <TableRow key={index}>
                                <TableCell>{contribute.type}</TableCell>
                                <TableCell>{contribute.alias}</TableCell>
                                <TableCell>
                                  <JSONDialog
                                    input_json={state.database[
                                      `${contribute.type}_profiles`
                                    ]?.find(
                                      (profile) =>
                                        profile.uid === contribute.profile_uid
                                    )}
                                    name={contribute.alias}
                                    enableEdit={false}
                                    handleNewJSON={() => {}}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {item.edit.length > 0 && (
                    <Box>
                      <Typography
                        style={{ fontWeight: "bolder", margin: "5px" }}
                      >
                        Edit
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Alias</TableCell>
                              <TableCell>Profile</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.edit.map((edit, index) => (
                              <TableRow key={index}>
                                <TableCell>{edit.type}</TableCell>
                                <TableCell>{edit.alias}</TableCell>
                                <TableCell>
                                  <JSONDialog
                                    input_json={state.database[
                                      `${edit.type}_profiles`
                                    ]?.find(
                                      (profile) =>
                                        profile.uid === edit.profile_uid
                                    )}
                                    name={edit.alias}
                                    enableEdit={false}
                                    handleNewJSON={() => {}}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {item.monitor.length > 0 && (
                    <Box>
                      <Typography
                        style={{ fontWeight: "bolder", margin: "5px" }}
                      >
                        Monitor
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Alias</TableCell>
                              <TableCell>Profile</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.monitor.map((monitor, index) => (
                              <TableRow key={index}>
                                <TableCell>{monitor.type}</TableCell>
                                <TableCell>{monitor.alias}</TableCell>
                                <TableCell>
                                  <JSONDialog
                                    input_json={state.database[
                                      `${monitor.type}_profiles`
                                    ]?.find(
                                      (profile) =>
                                        profile.uid === monitor.profile_uid
                                    )}
                                    name={monitor.alias}
                                    enableEdit={false}
                                    handleNewJSON={() => {}}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Box>
              </Card>
            );
          }
          return null;
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventForm;
