import React, { useState, useContext } from "react";
import { MyContext } from "../../context/Context";
import TimezoneSelector from "../Utility/TimezoneSelector";
import Box from "@mui/material/Box";
import SetDarkMode from "./SetDarkMode";
import { useApiRequest } from "../Utility/useAPIRequest";
import { Button, Typography, Container, Paper } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import PersonIcon from "@mui/icons-material/Person";
function Settings() {
  const { state, dispatch } = useContext(MyContext);
  const { onSuccessfulModification } = useContext(MyContext);
  const { makeApiRequest } = useApiRequest();

  const saveSettingsToDB = async () => {
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        command: "command_any_table",
        obj: {
          command: "put",
          item: state.settings,
          table_name: "user_settings",
        },
      }),
    };
    const { success, data, error } = await makeApiRequest(endpoint, options);
    if (success) {
      console.log(data);
    } else {
      console.error("Failed to fetch data:", error);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "40px" }}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Box
          display="flex"
          alignItems="center"
          gap="5px"
          marginBottom="25px"
          justifyContent={"center"}
        >
          <PersonIcon />
          <Typography variant="h6">User Settings</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="20px">
          <TimezoneSelector />
          <SetDarkMode />
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveSettingsToDB()}
          >
            <SaveIcon style={{ marginRight: "10px" }}></SaveIcon>
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default Settings;
