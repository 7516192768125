import React from "react";
import { Grid, Typography, Link, Paper } from "@mui/material";

const PromexDetails = ({ promex }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        Distributions:{" "}
        {Array.isArray(promex["distributions"])
          ? promex["distributions"].join(", ")
          : ""}{" "}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          Host:{" "}
          <Link href={`https://${promex["host"]}`} underline="hover">
            {promex["host"]}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Device ID: {promex["device_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Region: {promex["region"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>AWS Instance ID: {promex["aws_instance_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {promex["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {promex["internal_ip"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default PromexDetails;
