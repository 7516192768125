import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import useConfirm from "../Utility/useConfirm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
export default function ContributionSimulatorStarter() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(
    "https://d2grt6ojtzqecj.cloudfront.net/match-recordings/gc23-hai-v-mex-clean.ts"
  );
  const [destinationUrls, setDestinationUrls] = useState([
    "srt://host:port?mode=caller&passphrase=xyz",
  ]);
  const [serviceName, setServiceName] = useState("Your Name");
  const [loading, setLoading] = useState(false);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartContainer = async () => {
    console.log("Starting container with:", {
      mediaUrl,
      destinationUrls,
      serviceName,
    });
    setLoading(true);
    try {
      await requestConfirm(
        "Are you sure you want to start this container? " + serviceName
      );
      let url = process.env.REACT_APP_START_CONTRIBUTION_SIMULATOR_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            service_name: serviceName.replace(" ", "_"),
            media_url: mediaUrl,
            destination_urls: destinationUrls,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
    setLoading(false);
    handleClose();
  };

  const handleAddDestinationUrl = () => {
    setDestinationUrls([...destinationUrls, ""]);
  };

  const handleRemoveDestinationUrl = (index) => {
    const newUrls = destinationUrls.filter((_, idx) => idx !== index);
    setDestinationUrls(newUrls);
  };

  const handleDestinationUrlChange = (index, value) => {
    const newUrls = destinationUrls.map((url, idx) =>
      idx === index ? value : url
    );
    setDestinationUrls(newUrls);
  };

  return (
    <div>
      {ConfirmDialog}

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{
          borderBottomRightRadius: "0px",
          borderBottomLeftRadius: "0px",
          minWidth: "150px",
          marginRight: "10px",
          marginLeft: "20px",
        }}
      >
        <AddCircleIcon></AddCircleIcon>
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Start Contribution Simulator</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Service Name"
                type="text"
                fullWidth
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                style={{ marginTop: "16px" }}
              />
              <TextField
                margin="dense"
                label="Media URL"
                type="url"
                fullWidth
                value={mediaUrl}
                onChange={(e) => setMediaUrl(e.target.value)}
              />
              <Box mt={2}>
                <Typography variant="h6">Destination URLs</Typography>
                {destinationUrls.map((url, index) => (
                  <Box key={index} display="flex" alignItems="center" mt={1}>
                    <TextField
                      margin="dense"
                      label={`Destination URL ${index + 1}`}
                      type="url"
                      fullWidth
                      value={url}
                      onChange={(e) =>
                        handleDestinationUrlChange(index, e.target.value)
                      }
                    />
                    <IconButton
                      onClick={() => handleRemoveDestinationUrl(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddDestinationUrl}
                  style={{ marginTop: "8px" }}
                >
                  Add Destination
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleStartContainer}
            color="primary"
            variant="contained"
            disabled={
              loading ||
              destinationUrls.length == 0 ||
              mediaUrl == "" ||
              serviceName == ""
            }
          >
            Start Container
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
