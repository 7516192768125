import React, { useState, useContext } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import JSONDialog from "../Utility/JSONDialog"; // Assuming JSONDialog exists
import moment from "moment-timezone";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { useTheme } from "@mui/material/styles";
import ScheduleUpdater from "./ScheduleUpdater";
import useCronTranslator from "../Utility/useCronTranslator";
import { MyContext } from "../../context/Context";
import useDateToUnixMs from "../Utility/useDateToUnixMs";

const ArchivedScheduleRow = ({ schedule, timezone, rowIndex, onDelete }) => {
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const isOdd = rowIndex % 2 !== 0;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const translateCronExpression = useCronTranslator(timezone);
  const { makeApiRequest } = useApiRequest();
  const { onSuccessfulModification } = useContext(MyContext);
  const convertToUnixMs = useDateToUnixMs();

  const handleDelete = async () => {
    console.log(schedule);
    onDelete(
      [schedule],
      "Are you sure you want to delete this archived schedule?"
    );
  };
  const reRunSchedule = async (time, tz) => {
    try {
      await requestConfirm("Are you sure you want to recreate this schedule?");
      let url = process.env.REACT_APP_TASKS_URL;
      let extime = convertToUnixMs(time, tz);
      let input = {
        command: "recreate_schedule",
        input: {
          input: schedule.input,
          function_name: schedule.function_name,
          schedule_name: schedule.schedule_name,
          execution_time: extime,
          schedule_description: schedule.schedule_description,
        },
      };
      console.log(input);
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(input),
        },
        onSuccessfulModification
      );
      if (result.success) {
        setOpen(false);
      }
      return result;
    } catch (error) {
      console.log("Delete action cancelled by user");
    }
  };

  return (
    <React.Fragment>
      {ConfirmDialog}
      <TableRow
        style={{
          backgroundColor: isOdd
            ? theme.palette.mode === "dark"
              ? "#2e2e2e"
              : "#f1f1f1"
            : "",
        }}
      >
        {" "}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {(() => {
            const [event, number, task, type, index] =
              schedule.schedule_description.split(";");
            return (
              <span>
                Event: <strong>{event}</strong>, Match#:{" "}
                <strong>{number}</strong>, Task:{" "}
                <strong>{task.replace(/_/g, " ")}</strong>, Type:{" "}
                <strong>{type.charAt(0).toUpperCase() + type.slice(1)}</strong>,
                Index: <strong>{index}</strong>
              </span>
            );
          })()}
        </TableCell>
        <TableCell>
          {moment(Number(schedule.execution_time))
            .tz(timezone)
            .format("MM/DD/YYYY HH:mm:ss")}{" "}
        </TableCell>
        {/* <TableCell component="th" scope="row">
          <div style={{ fontSize: "12px", color: "grey" }}>
            {schedule.schedule_name}
          </div>
        </TableCell> */}
        <TableCell component="th" scope="row">
          <JSONDialog
            input_json={schedule.input}
            name={schedule.schedule_name}
            execution_time={moment(Number(schedule.execution_time))
              .tz(timezone)
              .format("MM/DD/YYYY HH:mm:ss")}
            timezone={timezone}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <JSONDialog
            input_json={schedule.response}
            name={schedule.schedule_name}
            execution_time={moment(Number(schedule.execution_time))
              .tz(timezone)
              .format("MM/DD/YYYY HH:mm:ss")}
            timezone={timezone}
          />
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            colSpan: 6,
            backgroundColor: isOdd
              ? theme.palette.mode === "dark"
                ? "#2e2e2e"
                : "#f1f1f1"
              : "",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <ScheduleUpdater onUpdateTime={reRunSchedule}></ScheduleUpdater>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ArchivedScheduleRow;
