// src/components/Utility/DeletionProgressDialog.js

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Typography,
  Box,
} from "@mui/material";

export default function DeletionProgressDialog({
  open,
  totalItems,
  currentItem,
  onClose,
}) {
  const progress = totalItems > 0 ? (currentItem / totalItems) * 100 : 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="deletion-progress-dialog"
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Typography variant="body1">
            {`Deleting ${currentItem} of ${totalItems}`}
          </Typography>
          <Box width="100%" mt={2}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Typography variant="body2" color="textSecondary" mt={1}>
            {`${Math.round(progress)}% completed`}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
