import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import CustomDateTimePicker from "../Utility/CustomDateTimePicker";
import useConfirm from "../Utility/useConfirm";
import dayjs from "dayjs";

const EventTimeUpdater = ({ onSubmit, onCancel }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const { requestConfirm, ConfirmDialog } = useConfirm();

  const handleSubmit = async () => {
    try {
      await requestConfirm(
        "Are you sure you want to submit the selected times? The current config will be removed."
      );
      if (startTime && endTime) {
        onSubmit({ startTime, endTime });
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const formatTime = (timestamp) => {
    return timestamp ? dayjs(timestamp).format("MM/DD/YYYY HH:mm:ss") : "";
  };

  return (
    <>
      {ConfirmDialog}
      <Dialog open={true} onClose={onCancel}>
        <DialogTitle>Select Start and End Time</DialogTitle>
        <Box padding={2} display="flex" flexDirection="column" gap={2}>
          {/* Start Time Section */}
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              margin="dense"
              label="Start Time"
              value={formatTime(startTime)}
              onChange={() => {}}
              disabled
              fullWidth
            />
            <CustomDateTimePicker onConfirm={(value) => setStartTime(value)} />
          </Box>

          {/* End Time Section */}
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              margin="dense"
              label="End Time"
              value={formatTime(endTime)}
              onChange={() => {}}
              disabled
              fullWidth
            />
            <CustomDateTimePicker onConfirm={(value) => setEndTime(value)} />
          </Box>
        </Box>
        <DialogActions>
          <Button onClick={onCancel} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventTimeUpdater;
