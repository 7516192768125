import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CustomDateTimePicker from "../Utility/CustomDateTimePicker";
import dayjs from "dayjs";
import { useApiRequest } from "../Utility/useAPIRequest";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FilterIcon from "@mui/icons-material/Filter";
import PublicIcon from "@mui/icons-material/Public";
import CastIcon from "@mui/icons-material/Cast";

function MixTvuDetails({ mixConfig, mixIndex, eventUid }) {
  const [copySuccess, setCopySuccess] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [goLive, setGoLive] = useState(true);
  const [isEditEndTime, setIsEditEndTime] = useState(false); // State for edit checkbox
  const { makeApiRequest } = useApiRequest();

  useEffect(() => {
    const startFromConfig = mixConfig?.schedule_api_config?.start_time;
    const endFromConfig = mixConfig?.schedule_api_config?.end_time;

    const now = dayjs();
    const defaultStartTime = now.add(2, "minute").valueOf();
    const defaultEndTime = now.add(1, "hour").add(2, "minute").valueOf();

    setStartTime(
      startFromConfig
        ? dayjs.unix(startFromConfig / 1000).valueOf()
        : defaultStartTime
    );
    setEndTime(
      endFromConfig
        ? dayjs.unix(endFromConfig / 1000).valueOf()
        : defaultEndTime
    );
  }, [mixConfig]);

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess(`Link ${index + 1} copied!`);
        setTimeout(() => setCopySuccess(""), 2000);
      },
      (err) => {
        console.error("Error copying text to clipboard", err);
      }
    );
  };

  const handleCopyTvuEvent = () => {
    console.log("Mix index:", mixIndex);
    console.log("Start time:", startTime);
    console.log("End time:", endTime);
    console.log("Event UID:", eventUid);
    console.log("Go live:", goLive);
    setOpenDialog(false);

    let payload = {
      start_time: startTime,
      end_time: endTime,
      mix_config_index: mixIndex,
      event_uid: eventUid,
      go_live: goLive,
    };
    handleConfigure(payload);
  };

  const handleConfigure = async (payload) => {
    try {
      const endpoint = process.env.REACT_APP_TASKS_URL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          command: "copy_tvu_event",
          input: payload,
        }),
      };
      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const handleDateTimeConfirm = (unixTimestampMs, field) => {
    if (field === "start") setStartTime(unixTimestampMs);
    if (field === "end") setEndTime(unixTimestampMs);
  };

  const formatTime = (timestamp) => {
    return timestamp ? dayjs(timestamp).format("MM/DD/YYYY HH:mm:ss") : "";
  };

  return (
    <Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <p>
          <strong>{mixConfig.profile.alias}</strong>
        </p>
      </Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <CastIcon></CastIcon>
        <p>
          Destination route:{" "}
          <strong>{mixConfig.route_name.toUpperCase()} </strong>
        </p>
      </Box>
      <Box display="flex" alignItems="center" gap="10px" height="30px">
        <PublicIcon />

        <p>
          Region: <strong>{mixConfig.profile.region}</strong>
        </p>
      </Box>
      {Array.isArray(mixConfig.profile.graphics) &&
        mixConfig.profile.graphics.map((graphic, graphicIndex) => (
          <Box
            key={graphicIndex}
            display="flex"
            gap="10px"
            height="35px"
            alignItems="center"
          >
            {/* Clickable FilterIcon that opens the URL in a new tab */}

            <Box
              onClick={() =>
                window.open(graphic.url, "_blank", "noopener,noreferrer")
              }
              sx={{ cursor: "pointer" }} // Add pointer cursor on hover
            >
              <Tooltip title={`Open Graphics`}>
                <FilterIcon />
              </Tooltip>
            </Box>

            <p>
              Graphics: <strong>{graphic.alias}</strong>
            </p>
          </Box>
        ))}
      {Array.isArray(mixConfig.api_response_store.schedule_event.commentary) &&
        mixConfig.api_response_store.schedule_event.commentary.length > 0 && (
          <Box style={{ width: "100%" }}>
            <List
              style={{
                width: "100%",
                margin: "0px",
                padding: "0px",
                borderBottom: "0px solid grey",
              }}
            >
              {mixConfig.api_response_store.schedule_event.commentary.map(
                (commentaryItem, commentaryIndex) => (
                  <ListItem key={commentaryIndex} divider>
                    <Box display="flex" alignItems="center">
                      {/* Support Agent Icon */}
                      <SupportAgentIcon style={{ marginRight: "10px" }} />

                      {/* Commentator Link */}
                      <Link
                        href={commentaryItem.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: "15px" }}
                      >
                        {commentaryIndex + 1}: {commentaryItem.title}
                      </Link>

                      {/* Copy to Clipboard Button */}
                      {copySuccess && (
                        <Typography color="success.main">
                          {copySuccess}
                        </Typography>
                      )}
                      <Tooltip title="Copy to clipboard">
                        <IconButton
                          onClick={() =>
                            copyToClipboard(
                              commentaryItem.link,
                              commentaryIndex
                            )
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                )
              )}
            </List>
          </Box>
        )}
      <Tooltip title="Copy TVU Event">
        <Button
          onClick={() => setOpenDialog(true)}
          variant="contained"
          color="primary"
          style={{
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
            marginLeft: "10px",
          }}
        >
          <AddToPhotosIcon style={{ marginRight: "5px" }} />
        </Button>
      </Tooltip>

      {/* Dialog for entering start/end time and go_live */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create copy of TVU Event</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              margin="dense"
              label="TVU Start Time"
              value={formatTime(startTime)}
              onChange={() => {}}
              disabled
              fullWidth
            />
            <CustomDateTimePicker
              onConfirm={(unixTimestampMs) =>
                handleDateTimeConfirm(unixTimestampMs, "start")
              }
            />
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              margin="dense"
              label="TVU End Time"
              value={formatTime(endTime)}
              onChange={() => {}}
              disabled={!isEditEndTime} // Conditionally disable based on checkbox
              fullWidth
            />
            {isEditEndTime && (
              <CustomDateTimePicker
                onConfirm={(unixTimestampMs) =>
                  handleDateTimeConfirm(unixTimestampMs, "end")
                }
              />
            )}
          </Box>

          {/* Checkbox to toggle end time editability */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isEditEndTime}
                onChange={(e) => setIsEditEndTime(e.target.checked)}
              />
            }
            label="Edit TVU event end time"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={goLive}
                onChange={(e) => setGoLive(e.target.checked)}
              />
            }
            label="Go Live at configuration?"
          />
        </DialogContent>
        <Typography m={2}>
          TVU configuration task will be scheduled to run at selected start time
          + 10min.
        </Typography>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCopyTvuEvent}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MixTvuDetails;
