import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import JsonImporter from "../../Utility/JsonImporter";
import constants from '../../../context/constants.json';

const AddTVUMediaHubProfileForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);

  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    email: "",
    encoding_profile: constants.mix.tvu_mediahub.encoding_profiles[0] ?? {},
    region: "",
    rundown: [],
  });
  
  const [selectedRundownTask, setSelectedRundownTask] = useState("");
  const [error, setError] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleAddRundownTask = () => {
    if (selectedRundownTask) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        rundown: [...prevFormData.rundown, selectedRundownTask],
      }));
      setSelectedRundownTask(""); // Reset the selected rundown task after adding
    }
  };

  const handleRemoveRundownTask = (index) => {
    setFormData({
      ...formData,
      rundown: formData.rundown.filter((_, i) => i !== index),
    });
  };

  const handleClose = () => {
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };

  const handleSubmit = () => {
    if (!schema) {
      setError("Schema is not defined.");
      return;
    }
  
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && !formData[key]) {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });
  
    if (isValid) {
      onClose();
      setError("");
      onAdd(formData);
      setFormData({
        uid: uuidv4(),
        alias: "",
        email: "",
        encoding_profile: constants.mix.tvu_mediahub.encoding_profiles[0] ?? {},
        region: "",
        rundown: [],
      });
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Typography variant="body1">Add New TVU Media Hub Profile</Typography>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          margin="normal"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="encoding-profile-select-label">Encoding Profile</InputLabel>
          <Select
            labelId="encoding-profile-select-label"
            name="encoding_profile"
            value={formData.encoding_profile}
            onChange={(e) =>
              setFormData({
                ...formData,
                encoding_profile: e.target.value,
              })
            }
            label="Encoding Profile"
          >
            {constants.mix.tvu_mediahub.encoding_profiles.map((profile, index) => (
              <MenuItem key={index} value={profile}>
                {profile.profile_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          name="region"
          label="Region"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.region}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="rundown-tasks-select-label">Rundown Tasks</InputLabel>
          <Select
            labelId="rundown-tasks-select-label"
            value={selectedRundownTask.uid || ""}
            onChange={(e) => 
              setSelectedRundownTask(e.target.value)
            }
            label="Rundown Tasks"
          >
            {state.database.tvu_mediahub_abilities.map((ability) => (
              <MenuItem key={ability.uid} value={ability}>
                {ability.alias}
              </MenuItem>
            ))}
          </Select>
          {selectedRundownTask ? (
            <TextField
              autoFocus
              margin="normal"
              name="execution_offset"
              label="Execution Offset"
              type="text"
              fullWidth
              variant="outlined"
              value={selectedRundownTask.execution_offset}
              onChange={(e) => {
                setSelectedRundownTask({
                  ...selectedRundownTask,
                  execution_offset: e.target.value,
                });
              }}
            />
          ) : null}
          <Button
            variant="contained"
            onClick={handleAddRundownTask}
            style={{ marginTop: "10px" }}
            disabled={!selectedRundownTask}
          >
            Add Rundown Task
          </Button>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginTop: "40px" }}>
            {formData.rundown.map((task, index) => (
              <Chip
                key={task.uid + index}
                label={`${task.alias} : ${task.execution_offset}`}
                onDelete={() => handleRemoveRundownTask(index)}
                color="primary"
              />
            ))}
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTVUMediaHubProfileForm;
