import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const LoaderSkeleton = ({ skeletonCount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "90%",
        margin: "auto",
        paddingTop: "5%",
      }}
    >
      {Array.from(new Array(skeletonCount)).map((_, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
          {/* Simulate accordion header */}
          <Skeleton
            variant="rectangular"
            width="100%"
            height={50}
            animation="wave"
          />
          {/* Simulate accordion expanded area with varying content */}
          <Skeleton variant="text" animation="wave" sx={{ marginBottom: 1 }} />
          <Skeleton variant="text" width="60%" animation="wave" />
        </Box>
      ))}
    </Box>
  );
};

export default LoaderSkeleton;
