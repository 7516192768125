import React, { useState, useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import JsonImporter from "../../Utility/JsonImporter";
import MediationIcon from "@mui/icons-material/Mediation";
import RouteIcon from "@mui/icons-material/Route";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AddWorkflowProfileForm = ({ open, onClose, onAdd }) => {
  const { state } = useContext(MyContext);
  const [streamFromUID, setStreamFromUID] = "";
  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    exchange: [],
    mix: [],
    record: [],
    contribute: [],
    edit: [],
    monitor: [],
  });
  const [selectedProfiles, setSelectedProfiles] = useState({
    exchange: "",
    mix: "",
    record: "",
    exchange_type_profile: "",
    mix_type_profile: "",
    record_type_profile: "",
    contribute_type_profile: "",
    edit_type_profile: "",
    monitor_type_profile: "",
    contribute_destination: "",
    route: "",
    streamFromRoute: "",
  });
  const [error, setError] = useState("");

  const resetAllData = () => {
    setFormData({
      uid: uuidv4(),
      alias: "",
      exchange: [],
      mix: [],
      record: [],
      contribute: [],
      edit: [],
      monitor: [],
    });
    setSelectedProfiles({
      exchange: "",
      mix: "",
      record: "",
      contribute: "",
      edit: "",
      monitor: "",
      exchange_type_profile: "",
      mix_type_profile: "",
      record_type_profile: "",
      contribute_type_profile: "",
      edit_type_profile: "",
      monitor_type_profile: "",
      route: "",
      streamFromRoute: "",
      contribute_destination: "",
    });
    setError("");
  };

  useEffect(() => {
    // Reset form data when the dialog opens
    if (open) {
      resetAllData();
    }
  }, [open]);

  const handleProfileChange = (profile_ident, value) => {
    setSelectedProfiles((prev) => ({ ...prev, [profile_ident]: value }));
    if (profile_ident == "record") {
      setSelectedProfiles((prev) => ({
        ...prev,
        record_type_profile: "",
      }));
    }
    setError(""); // Clear previous errors
  };

  const handleSubmit = () => {
    if (!formData.alias) {
      setError("Alias is required.");
      return;
    }
    setError(""); // Clear previous errors
    onAdd(formData);
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleAddProfile = (profile, profile_ident) => {
    let newProfile;

    if (profile_ident === "mix_type_profile") {
      newProfile = {
        alias: profile.alias,
        type: selectedProfiles.mix.type,
        profile_uid: profile.uid,
        route_uid: selectedProfiles.route.route_uid,
        route_name: selectedProfiles.route.route_name,
        source_route_uid: selectedProfiles.streamFromRoute.route_uid,
        source_route_name: selectedProfiles.streamFromRoute.route_name,
      };
      const routeUidExists = formData.mix.some(
        (item) => item.route_uid === selectedProfiles.route.route_uid
      );
      if (!routeUidExists) {
        setFormData((prev) => ({
          ...prev,
          mix: [...prev.mix, newProfile],
        }));
        handleProfileChange("route_uid", "");
      }
      setSelectedProfiles((prev) => ({
        ...prev,
        route: "",
      }));
    } else if (profile_ident === "exchange_type_profile" && profile) {
      newProfile = {
        alias: profile.alias,
        type: selectedProfiles.exchange.type,
        profile_uid: profile.uid,
      };
      setFormData((prev) => ({
        ...prev,
        exchange: [...prev.exchange, newProfile],
      }));
    } else if (profile_ident === "record_type_profile") {
      newProfile = {
        alias: profile.alias,
        type: selectedProfiles.record.type,
        profile_uid: profile.uid,
        source_route_uid: selectedProfiles.sourceRoute?.route_uid, // add source route UID
        source_route_name: selectedProfiles.sourceRoute?.route_name, // add source route name
      };
      setFormData((prev) => ({
        ...prev,
        record: [...prev.record, newProfile],
      }));
    } else if (profile_ident === "contribute_type_profile") {
      newProfile = {
        alias: profile.alias,
        type: selectedProfiles.contribute.type,
        profile_uid: profile.uid,
        destination_route_uid:
          selectedProfiles.contribute_destination.route_uid,
        destination_route_name:
          selectedProfiles.contribute_destination.route_name,
      };
      setFormData((prev) => ({
        ...prev,
        contribute: [...prev.contribute, newProfile],
      }));
    } else if (profile_ident === "edit_type_profile") {
      newProfile = {
        alias: profile.alias,
        type: selectedProfiles.edit.type,
        profile_uid: profile.uid,
      };
      setFormData((prev) => ({
        ...prev,
        edit: [...prev.edit, newProfile],
      }));
    } else if (profile_ident === "monitor_type_profile") {
      newProfile = {
        alias: profile.alias,
        type: selectedProfiles.monitor.type,
        profile_uid: profile.uid,
      };
      setFormData((prev) => ({
        ...prev,
        monitor: [...prev.monitor, newProfile],
      }));
    }
  };

  const handleRemoveProfileByIndex = (index, profileIdent) => {
    setFormData((prev) => ({
      ...prev,
      [profileIdent]: prev[profileIdent].filter((_, i) => i !== index),
    }));

    if (profileIdent == "exchange") {
      resetAllData();
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            justifyContent={"space-between"}
            style={{ borderBottom: "2px solid grey", padding: "15px" }}
          >
            <Box display="flex" gap="10px">
              <MediationIcon />
              <Typography variant="body1">
                <strong>Add New Workflow</strong>
              </Typography>
            </Box>
            <JsonImporter onImport={handleJsonImport} />
          </Box>
        </DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            autoFocus
            margin="normal"
            name="uid"
            label="UID"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.uid}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            name="alias"
            label="Workflow Alias"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.alias}
            onChange={handleChange}
          />

          {/* Exchange Section */}
          <Box
            display="flex"
            style={{
              paddingTop: "20px",
              marginTop: "20px",
            }}
          >
            <RouteIcon />
            <Typography style={{ marginLeft: "5px" }}>
              <strong>Exchange</strong> (Single Exchange item supported only)
            </Typography>
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel>Exchange Profile</InputLabel>
            <Select
              value={selectedProfiles.exchange}
              onChange={(e) => handleProfileChange("exchange", e.target.value)}
              label="Exchange Profile"
            >
              {state.database.exchange_profiles
                ?.slice()
                .sort((a, b) =>
                  (a.alias || a.uid).localeCompare(b.alias || b.uid)
                )
                .map((profile) => (
                  <MenuItem key={profile.uid} value={profile}>
                    {profile.alias}
                  </MenuItem>
                ))}
            </Select>
            {selectedProfiles.exchange !== "" && (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>{selectedProfiles.exchange.type}</InputLabel>
                  <Select
                    value={selectedProfiles.exchange_type_profile}
                    onChange={(e) =>
                      handleProfileChange(
                        "exchange_type_profile",
                        e.target.value
                      )
                    }
                    label="Exchange Type Profile"
                  >
                    {state.database[
                      `${selectedProfiles.exchange.type}_profiles`
                    ]
                      ?.slice()
                      .sort((a, b) =>
                        (a.alias || a.uid).localeCompare(b.alias || b.uid)
                      )
                      .map((profile) => (
                        <MenuItem key={profile.uid} value={profile}>
                          {profile.alias}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={() =>
                    handleAddProfile(
                      selectedProfiles.exchange_type_profile,
                      "exchange_type_profile"
                    )
                  }
                  variant="contained"
                  style={{ marginTop: "10px" }}
                  disabled={
                    formData.exchange.length > 0 ||
                    selectedProfiles.exchange_type_profile === ""
                  }
                >
                  Add Exchange Item
                </Button>
              </>
            )}
            {formData.exchange.map((profile, index) => (
              <Chip
                key={profile.uid + index}
                label={`${profile.alias}: ProfileUid ${profile.profile_uid}, Type: ${profile.type}`}
                onDelete={() => handleRemoveProfileByIndex(index, "exchange")}
                deleteIcon={<DeleteIcon />}
                variant="contained"
                color="primary"
                sx={{ m: 1 }}
              />
            ))}
          </FormControl>
          {formData.exchange.length > 0 ? (
            <>
              {/* Mix Section with Accordion */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <VideoSettingsIcon
                    style={{
                      color: formData.mix.length == 0 ? "grey" : null,
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "5px",
                      color: formData.mix.length == 0 ? "grey" : null,
                    }}
                  >
                    <strong>Mix</strong>
                  </Typography>
                  <Typography style={{ marginLeft: "20px" }}>
                    {formData.mix.length} items
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Mix Profile</InputLabel>
                    <Select
                      value={selectedProfiles.mix}
                      onChange={(e) =>
                        handleProfileChange("mix", e.target.value)
                      }
                      label="Mix Profile"
                    >
                      {state.database.mix_profiles
                        ?.slice()
                        .sort((a, b) =>
                          (a.alias || a.uid).localeCompare(b.alias || b.uid)
                        )
                        .map((profile) => (
                          <MenuItem key={profile.uid} value={profile}>
                            {profile.alias}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {selectedProfiles.mix && (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>{selectedProfiles.mix.type}</InputLabel>
                        <Select
                          value={selectedProfiles.mix_type_profile}
                          onChange={(e) =>
                            handleProfileChange(
                              "mix_type_profile",
                              e.target.value
                            )
                          }
                          label="Mix Type Profile"
                        >
                          {state.database[
                            `${selectedProfiles.mix.type}_profiles`
                          ]
                            ?.slice()
                            .sort((a, b) =>
                              (a.alias || a.uid).localeCompare(b.alias || b.uid)
                            )
                            .map((profile) => (
                              <MenuItem key={profile.uid} value={profile}>
                                {profile.alias}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Stream from</InputLabel>
                        <Select
                          value={selectedProfiles.streamFromRoute}
                          onChange={(e) =>
                            handleProfileChange(
                              "streamFromRoute",
                              e.target.value
                            )
                          }
                          label="Stream from"
                        >
                          {selectedProfiles.exchange_type_profile?.routes?.map(
                            (route) => (
                              <MenuItem key={route.route_uid} value={route}>
                                {route.route_name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Stream to</InputLabel>
                        <Select
                          value={selectedProfiles.route}
                          onChange={(e) =>
                            handleProfileChange("route", e.target.value)
                          }
                          label="Stream to"
                        >
                          {selectedProfiles.exchange_type_profile?.routes?.map(
                            (route) =>
                              formData.mix.some(
                                (item) => item.route_uid === route.route_uid
                              ) ||
                              selectedProfiles.streamFromRoute.route_uid ===
                                route.route_uid ? null : (
                                <MenuItem key={route.route_uid} value={route}>
                                  {route.route_name}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                      <Button
                        onClick={() =>
                          handleAddProfile(
                            selectedProfiles.mix_type_profile,
                            "mix_type_profile"
                          )
                        }
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        disabled={
                          selectedProfiles.mix === "" ||
                          selectedProfiles.mix_type_profile === "" ||
                          selectedProfiles.route === ""
                        }
                      >
                        Add Mix Item
                      </Button>
                    </>
                  )}
                  {formData.mix.map((profile, index) => (
                    <Chip
                      key={index}
                      label={`Route: Mix streams from ${profile.source_route_name} to ${profile.route_name} using ${profile.alias}: ProfileType: ${profile.type}, Index: ${index},ProfileUid: ${profile.profile_uid}`}
                      onDelete={() => handleRemoveProfileByIndex(index, "mix")}
                      deleteIcon={
                        <IconButton
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleRemoveProfileByIndex(index, "mix")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      color="primary"
                      variant="contained"
                      sx={{ m: 1, mr: 1 }}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>

              {/* Record Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <RadioButtonCheckedIcon
                    style={{
                      color: formData.record.length == 0 ? "grey" : null,
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "5px",
                      color: formData.record.length == 0 ? "grey" : null,
                    }}
                  >
                    <strong>Record</strong>
                  </Typography>
                  <Typography style={{ marginLeft: "20px" }}>
                    {formData.record.length} items
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Keep the existing logic here for Record */}
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Record Profile</InputLabel>
                    <Select
                      value={selectedProfiles.record}
                      onChange={(e) =>
                        handleProfileChange("record", e.target.value)
                      }
                      label="Record Profile"
                    >
                      {state.database.record_profiles
                        ?.slice() // Create a shallow copy of the array to prevent modifying the original array
                        .sort((a, b) => {
                          // Use localeCompare for a safe alphabetical sort that handles lowercase, uppercase and international characters consistently
                          const nameA = a.alias || a.uid; // Fallback to uid if alias is not present
                          const nameB = b.alias || b.uid; // Fallback to uid if alias is not present
                          return nameA.localeCompare(nameB);
                        })
                        .map((profile, index) => (
                          <MenuItem key={profile.uid + index} value={profile}>
                            {profile.alias}
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedProfiles.record !== "" ? (
                      <div>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>
                            {selectedProfiles.record.type}{" "}
                          </InputLabel>
                          <Select
                            value={selectedProfiles.record_type_profile}
                            onChange={(e) =>
                              handleProfileChange(
                                "record_type_profile",
                                e.target.value
                              )
                            }
                            label="Record Type Profile"
                          >
                            {state.database[
                              `${selectedProfiles.record.type}_profiles`
                            ]
                              ?.slice() // Create a shallow copy of the array to prevent modifying the original array
                              .sort((a, b) => {
                                const nameA = a.alias || a.uid; // Fallback to uid if alias is not present
                                const nameB = b.alias || b.uid; // Fallback to uid if alias is not present
                                return nameA.localeCompare(nameB);
                              })
                              .map((profile) => (
                                <MenuItem key={profile.uid} value={profile}>
                                  {profile.alias}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Record feed</InputLabel>
                          <Select
                            value={selectedProfiles.sourceRoute}
                            onChange={(e) =>
                              handleProfileChange("sourceRoute", e.target.value)
                            }
                            label="Record Feed"
                          >
                            {selectedProfiles.exchange_type_profile?.routes?.map(
                              (route) => (
                                <MenuItem key={route.route_uid} value={route}>
                                  {route.route_name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>

                        <Button
                          onClick={() =>
                            handleAddProfile(
                              selectedProfiles.record_type_profile,
                              `record_type_profile`
                            )
                          }
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={selectedProfiles.record_type_profile === ""}
                        >
                          Add Record Item
                        </Button>
                      </div>
                    ) : null}
                  </FormControl>
                  {formData.record.map((profile, index) => (
                    <Chip
                      key={index}
                      label={`${profile.alias}: Feed: ${
                        profile.source_route_name
                      }, ProfileType: ${profile.type}, #${index + 1}`}
                      onDelete={() =>
                        handleRemoveProfileByIndex(index, "record")
                      }
                      deleteIcon={
                        <IconButton
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleRemoveProfileByIndex(index, "record")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      color="primary"
                      variant="contained"
                      sx={{ mb: 1, mr: 1 }}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>

              {/* Contribute Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <VideoCallIcon
                    style={{
                      color: formData.contribute.length == 0 ? "grey" : null,
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "5px",
                      color: formData.contribute.length == 0 ? "grey" : null,
                    }}
                  >
                    <strong>Contribute</strong>
                  </Typography>
                  <Typography style={{ marginLeft: "20px" }}>
                    {formData.contribute.length} items
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Contribute Profile</InputLabel>
                    <Select
                      value={selectedProfiles.contribute}
                      onChange={(e) =>
                        handleProfileChange("contribute", e.target.value)
                      }
                      label="Contribute Profile"
                    >
                      {state.database.contribute_profiles
                        ?.slice()
                        .sort((a, b) => {
                          const nameA = a.alias || a.uid;
                          const nameB = b.alias || b.uid;
                          return nameA.localeCompare(nameB);
                        })
                        .map((profile, index) => (
                          <MenuItem key={profile.uid + index} value={profile}>
                            {profile.alias}
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedProfiles.contribute !== "" ? (
                      <div>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>
                            {selectedProfiles.contribute.type}{" "}
                          </InputLabel>
                          <Select
                            value={selectedProfiles.contribute_type_profile}
                            onChange={(e) =>
                              handleProfileChange(
                                "contribute_type_profile",
                                e.target.value
                              )
                            }
                            label="Contribute Type Profile"
                          >
                            {state.database[
                              `${selectedProfiles.contribute.type}_profiles`
                            ]
                              ?.slice()
                              .sort((a, b) => {
                                const nameA = a.alias || a.uid;
                                const nameB = b.alias || b.uid;
                                return nameA.localeCompare(nameB);
                              })
                              .map((profile) => (
                                <MenuItem key={profile.uid} value={profile}>
                                  {profile.alias}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Stream to</InputLabel>
                          <Select
                            value={selectedProfiles.contribute_destination}
                            onChange={(e) =>
                              handleProfileChange(
                                "contribute_destination",
                                e.target.value
                              )
                            }
                            label="Stream to"
                          >
                            {selectedProfiles.exchange_type_profile?.routes?.map(
                              (route, index) =>
                                formData.contribute.some(
                                  (item) => item.route_uid === route.route_uid
                                ) ? null : (
                                  <MenuItem key={route.route_uid} value={route}>
                                    {route.route_name}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                        </FormControl>
                        <Button
                          onClick={() =>
                            handleAddProfile(
                              selectedProfiles.contribute_type_profile,
                              `contribute_type_profile`
                            )
                          }
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={
                            selectedProfiles.contribute === "" ||
                            selectedProfiles.contribute_type_profile === ""
                          }
                        >
                          Add Contribute Item
                        </Button>
                      </div>
                    ) : null}
                  </FormControl>
                  {formData.contribute.map((profile, index) => (
                    <Chip
                      key={index}
                      label={`Contribution to ${profile.destination_route_name} using ${profile.alias}: ProfileType: ${profile.type}, Index: ${index}, ProfileUid: ${profile.profile_uid}`}
                      onDelete={() =>
                        handleRemoveProfileByIndex(index, "contribute")
                      }
                      deleteIcon={
                        <IconButton
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleRemoveProfileByIndex(index, "contribute")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      color="primary"
                      variant="contained"
                      sx={{ mb: 1, mr: 1 }}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>

              {/* Edit Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ContentCutIcon
                    style={{
                      color: formData.edit.length == 0 ? "grey" : null,
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "5px",
                      color: formData.edit.length == 0 ? "grey" : null,
                    }}
                  >
                    <strong>Edit</strong>
                  </Typography>
                  <Typography style={{ marginLeft: "20px" }}>
                    {formData.edit.length} items
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Edit Profile</InputLabel>
                    <Select
                      value={selectedProfiles.edit}
                      onChange={(e) =>
                        handleProfileChange("edit", e.target.value)
                      }
                      label="Edit Profile"
                    >
                      {state.database.edit_profiles
                        ?.slice()
                        .sort((a, b) => {
                          const nameA = a.alias || a.uid;
                          const nameB = b.alias || b.uid;
                          return nameA.localeCompare(nameB);
                        })
                        .map((profile, index) => (
                          <MenuItem key={profile.uid + index} value={profile}>
                            {profile.alias}
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedProfiles.edit !== "" ? (
                      <div>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>{selectedProfiles.edit.type}</InputLabel>
                          <Select
                            value={selectedProfiles.edit_type_profile}
                            onChange={(e) =>
                              handleProfileChange(
                                "edit_type_profile",
                                e.target.value
                              )
                            }
                            label="Edit Type Profile"
                          >
                            {state.database[
                              `${selectedProfiles.edit.type}_profiles`
                            ]
                              ?.slice()
                              .sort((a, b) => {
                                const nameA = a.alias || a.uid;
                                const nameB = b.alias || b.uid;
                                return nameA.localeCompare(nameB);
                              })
                              .map((profile) => (
                                <MenuItem key={profile.uid} value={profile}>
                                  {profile.alias}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <Button
                          onClick={() =>
                            handleAddProfile(
                              selectedProfiles.edit_type_profile,
                              `edit_type_profile`
                            )
                          }
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={selectedProfiles.edit_type_profile === ""}
                        >
                          Add Edit Item
                        </Button>
                      </div>
                    ) : null}
                  </FormControl>
                  {formData.edit.map((profile, index) => (
                    <Chip
                      key={index}
                      label={`Edit using ${profile.alias}: ProfileType: ${profile.type}, Index: ${index}, ProfileUid: ${profile.profile_uid}`}
                      onDelete={() => handleRemoveProfileByIndex(index, "edit")}
                      deleteIcon={
                        <IconButton
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleRemoveProfileByIndex(index, "edit")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      color="primary"
                      variant="contained"
                      sx={{ mb: 1, mr: 1 }}
                    />
                  ))}{" "}
                </AccordionDetails>
              </Accordion>

              {/* Monitor Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MonitorHeartIcon
                    style={{
                      color: formData.monitor.length == 0 ? "grey" : null,
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "5px",
                      color: formData.monitor.length == 0 ? "grey" : null,
                    }}
                  >
                    <strong>Monitor</strong>
                  </Typography>
                  <Typography style={{ marginLeft: "20px" }}>
                    {formData.monitor.length} items
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Monitoring Profile</InputLabel>
                    <Select
                      value={selectedProfiles.monitor}
                      onChange={(e) =>
                        handleProfileChange("monitor", e.target.value)
                      }
                      label="Monitor Profile"
                    >
                      {state.database.monitoring_profiles
                        ?.slice()
                        .sort((a, b) => {
                          const nameA = a.alias || a.uid;
                          const nameB = b.alias || b.uid;
                          return nameA.localeCompare(nameB);
                        })
                        .map((profile, index) => (
                          <MenuItem key={profile.uid + index} value={profile}>
                            {profile.alias}
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedProfiles.monitor !== "" ? (
                      <div>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>
                            {selectedProfiles.monitor.type}
                          </InputLabel>
                          <Select
                            value={selectedProfiles.monitor_type_profile}
                            onChange={(e) =>
                              handleProfileChange(
                                "monitor_type_profile",
                                e.target.value
                              )
                            }
                            label="Monitor Type Profile"
                          >
                            {state.database[
                              `${selectedProfiles.monitor.type}_profiles`
                            ]
                              ?.slice()
                              .sort((a, b) => {
                                const nameA = a.alias || a.uid;
                                const nameB = b.alias || b.uid;
                                return nameA.localeCompare(nameB);
                              })
                              .map((profile) => (
                                <MenuItem key={profile.uid} value={profile}>
                                  {profile.alias}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <Button
                          onClick={() =>
                            handleAddProfile(
                              selectedProfiles.monitor_type_profile,
                              `monitor_type_profile`
                            )
                          }
                          variant="contained"
                          style={{ marginTop: "10px" }}
                          disabled={
                            selectedProfiles.monitor_type_profile === ""
                          }
                        >
                          Add Monitor Item
                        </Button>
                      </div>
                    ) : null}
                  </FormControl>
                  {formData.monitor.map((profile, index) => (
                    <Chip
                      key={index}
                      label={`Monitor using ${profile.alias}: ProfileType: ${profile.type}, Index: ${index}, ProfileUid: ${profile.profile_uid}`}
                      onDelete={() =>
                        handleRemoveProfileByIndex(index, "monitor")
                      }
                      deleteIcon={
                        <IconButton
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleRemoveProfileByIndex(index, "monitor")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      color="primary"
                      variant="contained"
                      sx={{ mb: 1, mr: 1 }}
                    />
                  ))}{" "}
                </AccordionDetails>
              </Accordion>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={formData.exchange.length === 0}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddWorkflowProfileForm;
