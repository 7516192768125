import React from "react";
import { Grid, Typography, Link, Paper } from "@mui/material";

const CloudcutterDetails = ({ cloudcutter }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        Distributions:{" "}
        {Array.isArray(cloudcutter["distributions"])
          ? cloudcutter["distributions"].join(", ")
          : ""}{" "}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          Host:{" "}
          <Link href={`https://${cloudcutter["host"]}`} underline="hover">
            {cloudcutter["host"]}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Device ID: {cloudcutter["device_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Region: {cloudcutter["region"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          AWS Instance ID: {cloudcutter["aws_instance_id"]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {cloudcutter["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {cloudcutter["internal_ip"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default CloudcutterDetails;
