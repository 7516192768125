import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
// Hook that manages the confirmation dialog
const useConfirm = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [resolveReject, setResolveReject] = React.useState([]);
  const [message, setMessage] = React.useState("");

  const requestConfirm = (message = "Are you sure?") => {
    setMessage(message);
    setIsOpen(true);
    return new Promise((resolve, reject) => {
      setResolveReject([resolve, reject]);
    });
  };

  const handleClose = (didConfirm) => {
    setIsOpen(false);
    // Resolve or reject the promise based on user action
    const [resolve, reject] = resolveReject;
    if (didConfirm) {
      resolve(true);
    } else {
      reject(new Error("User did not confirm"));
    }
    // Reset for next use
    setResolveReject([]);
    setTimeout(() => {
      setMessage("Default message");
    }, 500);
  };

  // The dialog component
  const ConfirmDialog = (
    <Dialog open={isOpen} onClose={() => handleClose(false)}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "400px",
        }}
      >
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "right", gap: "5px" }}
        >
          <Button
            onClick={() => handleClose(false)}
            color="primary"
            variant="contained"
          >
            <CloseIcon></CloseIcon> Cancel
          </Button>
          <Button
            onClick={() => handleClose(true)}
            color="secondary"
            autoFocus
            variant="contained"
          >
            <CheckIcon> </CheckIcon> Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );

  return { requestConfirm, ConfirmDialog };
};

export default useConfirm;
