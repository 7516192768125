import React, { useState, useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import JsonImporter from "../../Utility/JsonImporter";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
const AddNabletsForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);
  const [selectedDistribution, setSelectedDistribution] = useState("");

  const [formData, setFormData] = useState(() => {
    const initialData = { uid: uuidv4() };
    Object.keys(schema).forEach((key) => {
      if (key !== "uid") {
        initialData[key] = schema[key].type === "array" ? [] : "";
      }
    });
    return initialData;
  });

  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setError("");
  };
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleDistributionChange = (event) => {
    console.log(event.target.value);
    setSelectedDistribution(event.target.value);
  };
  const handleAddDistribution = () => {
    if (
      selectedDistribution &&
      !formData.distributions.includes(selectedDistribution)
    ) {
      console.log(selectedDistribution);
      setFormData({
        ...formData,
        distributions: [...formData.distributions, selectedDistribution],
      });
      setSelectedDistribution("");
    }
  };

  const handleRemoveDistribution = (distributionId) => {
    setFormData({
      ...formData,
      distributions: formData.distributions.filter(
        (distribution_item) => distribution_item.uid !== distributionId
      ),
    });
  };

  const validateData = () => {
    let isValid = true;
    Object.keys(schema).forEach((key) => {
      if (schema[key].required && formData[key] === "") {
        isValid = false;
      }
    });

    if (!isValid) {
      setError("All required fields must be filled.");
      return false;
    }
    setError("");
    return true;
  };

  const handleClose = () => {
    const initialData = { uid: uuidv4() };
    Object.keys(schema).forEach((key) => {
      if (key !== "uid") {
        initialData[key] = schema[key].type === "array" ? [] : "";
      }
    });
    setFormData(initialData);
    setSelectedDistribution("");
    setError("");
    onClose();
  };

  const handleSubmit = () => {
    if (validateData()) {
      onAdd(formData);
      handleClose(); // Reset form with a new UID and clear fields after submission
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {" "}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1">Add New Nablet Item </Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {Object.keys(schema).map((key) =>
          key !== "distributions" ? (
            <TextField
              key={key}
              autoFocus={key === "uid"}
              margin="dense"
              name={key}
              label={
                key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")
              }
              type="text"
              fullWidth
              variant="outlined"
              value={formData[key]}
              onChange={handleChange}
              disabled={key === "uid"}
            />
          ) : null
        )}
        <FormControl fullWidth margin="dense">
          <InputLabel id="distribution-select-label">Distributions</InputLabel>
          <Select
            labelId="distribution-select-label"
            label="Distributions"
            id="distribution-select"
            value={selectedDistribution}
            onChange={handleDistributionChange}
            fullWidth
          >
            {state.database.distributions
              .slice() // Create a shallow copy of the array to prevent modifying the original array
              .sort((a, b) => {
                // Use localeCompare for a safe alphabetical sort that handles lowercase, uppercase and international characters consistently
                const nameA = a.alias || a.uid; // Fallback to uid if alias is not present
                const nameB = b.alias || b.uid; // Fallback to uid if alias is not present
                return nameA.localeCompare(nameB);
              })
              .map((option) => (
                <MenuItem key={option.uid} value={option}>
                  {option.alias}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleAddDistribution}
          variant="contained"
          style={{ marginTop: "1rem" }}
        >
          Add Distribution
        </Button>
        <Box display="flex" flexWrap="wrap" gap={1} marginTop={2}>
          {formData.distributions.map((distribution_item, index) => (
            <Chip
              key={index}
              label={
                state.database.distributions.find(
                  (option) => option.uid === distribution_item.uid
                )?.alias || distribution_item.uid
              }
              onDelete={() => handleRemoveDistribution(distribution_item.uid)}
              color="primary"
              style={{ margin: "5px" }}
            />
          ))}
        </Box>
        {error && (
          <Alert severity="error" style={{ marginTop: "1rem" }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNabletsForm;
