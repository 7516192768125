import React, { useState, useContext, useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Box } from "@mui/material";

export default function ContributionSimulatorService({ service }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleRestart = () => {
    // Implement the logic to restart the service
    console.log(`Restarting service: ${service.serviceName}`);
  };

  const handleDelete = async (service_name) => {
    try {
      await requestConfirm(
        "Are you sure you want delete this container? " + service_name
      );
      let url = process.env.REACT_APP_DELETE_CONTRIBUTION_SIMULATOR_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            service_name: service_name,
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  return (
    <div>
      {ConfirmDialog}
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Box display="flex" alignItems={"center"} gap="10px">
            <VideocamIcon color="primary"></VideocamIcon>
            <Typography variant="h6" component="div">
              Service Name: {service.serviceName}
            </Typography>
          </Box>
          <Typography color="text.secondary" gutterBottom>
            Status: {service.status}
          </Typography>
          {/* <Button
              variant="contained"
              color="primary"
              onClick={handleRestart}
              sx={{ marginRight: 1 }}
            >
              Restart
            </Button> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDelete(service.serviceName);
            }}
          >
            Delete
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
