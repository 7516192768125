import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Paper,
  TablePagination,
  TableSortLabel,
  Typography,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../context/Context";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useApiRequest } from "../Utility/useAPIRequest";
import MixConfigs from "./MixConfigs";
import schemas from "../Database/schemas.json";
import useConfirm from "../Utility/useConfirm";
import JSONDialog from "../Utility/JSONDialog";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { useTheme } from "@mui/material/styles";
import Bookmark from "@mui/icons-material/Bookmark";
import PanoramaWideAngleSelectIcon from "@mui/icons-material/PanoramaWideAngleSelect";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import EventTimeUpdater from "./EventTimeUpdater";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
export default function EventRow({ event, isPast }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [refError, setRefError] = useState(false);
  const { makeApiRequest } = useApiRequest();
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const [isPickerOpen, setPickerOpen] = useState(false);
  const theme = useTheme();
  const rowStyle = isPast
    ? { backgroundColor: theme.palette.mode === "dark" ? "#2e2e2e" : "#f1f1f1" }
    : {};

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  useEffect(() => {
    const checkForRefErrors = () => {
      const itemSchema = schemas.tables.events.item_schema;

      // Iterate over each field in the schema
      for (const key in itemSchema) {
        if (itemSchema[key].tableReference) {
          const referencedTable = itemSchema[key].tableReference;

          // Check if the referenced item exists in the database
          const refExists = state.database[referencedTable]?.some(
            (item) => item.uid === event[key]
          );

          // If a reference error is found, set refError to true and exit early
          if (!refExists) {
            setRefError(true);
            return;
          }
        }
      }
      // No reference errors found; set refError to false
      setRefError(false);
    };

    checkForRefErrors();
  }, [event, state.database, schemas.tables.events.item_schema]);

  const handleClosePicker = () => {
    setPickerOpen(false);
  };
  const handleSubmitTimes = (times) => {
    // Create a copy of the event object to avoid mutating the original one
    const updatedEvent = { ...event };
    updatedEvent["start_time"] = times.startTime;
    updatedEvent["end_time"] = times.endTime;
    handleUpdateTime(updatedEvent);
    setPickerOpen(false);
  };

  const handleUpdateTime = async (updatedItem) => {
    let dbCommand1 = "command_any_table";
    let dbCommand2 = "put";
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        command: dbCommand1,
        obj: { command: dbCommand2, item: updatedItem, table_name: "events" },
      }),
    };
    const { success, data, error } = await makeApiRequest(
      endpoint,
      options,
      "events"
    );
    if (success) {
      console.log(data);
    } else {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleConfigure = async () => {
    try {
      if (Object.keys(event.config).length > 0) {
        await requestConfirm(
          "Are you sure you want to configure this event? CAUTION: The existing config & Mix event (TVU, Spalk etc.) for this event will be overwritten on the associated platform!"
        );
      } else {
        await requestConfirm("Are you sure you want to configure this event? ");
      }
      const endpoint = process.env.REACT_APP_TASKS_URL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          command: "create_event_config",
          input: { event_uid: event.uid },
        }),
      };
      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const handleRemoveConfigure = async () => {
    try {
      if (Object.keys(event.config).length > 0) {
        await requestConfirm(
          "Are you sure you want remove config of this event? CAUTION: The existing resources (TVU, MediaHub) for this event will be removed. Running <Create Config> will be required for production."
        );
      } else {
        await requestConfirm("Are you sure you want to remove the config? ");
      }
      const endpoint = process.env.REACT_APP_TASKS_URL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          command: "remove_event_config",
          input: { event_uid: event.uid },
        }),
      };
      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const handleSchedule = async () => {
    try {
      await requestConfirm(
        "Are you sure you want to schedule this event configuration? "
      );
      const endpoint = process.env.REACT_APP_TASKS_URL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          command: "schedule_event_config",
          input: { event_uid: event.uid },
        }),
      };
      console.log("request schedule" + JSON.stringify(options));

      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };
  const formatValue = (key, value) => {
    let tableName = "events";

    // Format timestamps
    if (schemas.tables[tableName]?.item_schema[key]?.format === "timestamp") {
      const formattedTime = moment
        .tz(Number(value), state.settings.timezone)
        .format("MM/DD/YYYY HH:mm"); // Display without seconds
      const timestamp = moment
        .tz(Number(value), state.settings.timezone)
        .format("MM/DD/YYYY HH:mm:ss"); // Raw value with seconds
      return (
        <>
          <div style={{ fontSize: "15px" }}>{formattedTime}</div>
          {/* <div style={{ fontSize: "12px", color: "grey" }}>{timestamp}</div> */}
        </>
      );
    }

    // Handle table references
    if (schemas.tables[tableName]?.item_schema[key]?.tableReference) {
      const referencedTable =
        schemas.tables[tableName]?.item_schema[key].tableReference;
      const referencedItem = state.database[referencedTable]?.find(
        (item) => item.uid === value
      );

      if (referencedItem) {
        const displayText =
          key === "distribution_uid" && referencedItem.distribution
            ? referencedItem.distribution
            : referencedItem.alias;

        return (
          <>
            <div style={{ fontSize: "15px" }}>
              {displayText.replace(/_/g, " ")}
            </div>
            {/* <div style={{ fontSize: "12px", color: "grey" }}>{value}</div> */}
          </>
        );
      } else {
        // If referenced item not found, display value in secondary color
        return (
          <div
            style={{ fontSize: "13px", color: theme.palette.secondary.main }}
          >
            <p>Reference Error</p>
            <p
              style={{ fontSize: "10px", color: theme.palette.secondary.main }}
            >
              {" "}
              {value}
            </p>
          </div>
        );
      }
    }

    // Default return
    return value;
  };

  const homeTeam = state.database.tournaments
    ?.find((profile) => profile.uid === event.tournament_uid)
    ?.teams.find((team) => team.uid === event.home_team);

  const awayTeam = state.database.tournaments
    ?.find((profile) => profile.uid === event.tournament_uid)
    ?.teams.find((team) => team.uid === event.away_team);

  return (
    <>
      {ConfirmDialog}
      <TableRow key={event.uid} style={rowStyle}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            maxWidth: "160px",
            wordWrap: "break-word",
          }}
        >
          <div style={{ fontSize: "10px", color: "grey" }}>{event.uid}</div>
        </TableCell>
        <TableCell>{event.alias.replace(/_/g, " ")}</TableCell>
        <TableCell>
          {}
          <Box display="flex" alignItems={"center"} gap={"5px"}>
            {homeTeam.logo_url == "" ? (
              <PanoramaWideAngleSelectIcon></PanoramaWideAngleSelectIcon>
            ) : (
              <img
                src={homeTeam.logo_url}
                style={{ maxWidth: "20px" }}
                alt="Home"
              />
            )}
            <Typography>{homeTeam.alias}</Typography>
          </Box>
          {/* <Typography style={{ fontSize: "8px" }}>{event.home_team}</Typography> */}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems={"center"} gap={"5px"}>
            {awayTeam.logo_url == "" ? (
              <PanoramaWideAngleSelectIcon></PanoramaWideAngleSelectIcon>
            ) : (
              <img
                src={awayTeam.logo_url}
                style={{ maxWidth: "20px" }}
                alt="Away"
              />
            )}
            <Typography>{awayTeam.alias}</Typography>
          </Box>
          {/* <Typography style={{ fontSize: "8px" }}>{event.away_team}</Typography> */}
        </TableCell>

        <TableCell>{formatValue("start_time", event.start_time)}</TableCell>
        <TableCell>{formatValue("end_time", event.end_time)}</TableCell>
        <TableCell>{event.number}</TableCell>
        <TableCell>{event.stage.replace(/_/g, " ")}</TableCell>
        <TableCell>
          {formatValue("tournament_uid", event.tournament_uid)}
        </TableCell>
        <TableCell>
          {formatValue("workflow_profile_uid", event.workflow_profile_uid)}
        </TableCell>
        <TableCell>
          {formatValue("distribution_uid", event.distribution_uid)}
        </TableCell>
        <TableCell>
          {Object.keys(event.config).length > 0 ? (
            <>
              <Box display="flex">
                <JSONDialog
                  input_json={event.config}
                  name={event.alias}
                ></JSONDialog>
              </Box>
            </>
          ) : (
            <Typography
              style={{
                color: theme.palette.secondary.main,
              }}
            >
              {" "}
              No Config
            </Typography>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={13}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display={"flex"} gap={"30px"}>
              <Button
                onClick={handleConfigure}
                variant="contained"
                color="primary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "10vw",
                  minWidth: "150px",
                }}
                disabled={
                  Object.keys(event.config).length > 0 || isPast || refError
                }
              >
                {" "}
                <AddCircleOutlineIcon
                  style={{ marginRight: "5px" }}
                ></AddCircleOutlineIcon>
                Create config
              </Button>
              <Button
                onClick={handleRemoveConfigure}
                disabled={!Object.keys(event.config).length > 0}
                variant="contained"
                color="secondary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <RemoveCircleOutlineIcon
                  style={{ marginRight: "5px" }}
                ></RemoveCircleOutlineIcon>
                Remove Config
              </Button>
              <Button
                onClick={handleSchedule}
                disabled={!Object.keys(event.config).length > 0 || isPast}
                variant="contained"
                color="primary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <CalendarMonthIcon
                  style={{ marginRight: "5px" }}
                ></CalendarMonthIcon>
                Schedule Rundown
              </Button>
              <Button
                onClick={handleOpenPicker}
                variant="contained"
                color="secondary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
                disabled={Object.keys(event.config).length > 0}
              >
                <QueryBuilderIcon
                  style={{ marginRight: "5px" }}
                ></QueryBuilderIcon>
                Update Time
              </Button>
              {isPickerOpen && (
                <EventTimeUpdater
                  onSubmit={handleSubmitTimes}
                  onCancel={handleClosePicker}
                />
              )}
              <Button
                onClick={handleSchedule}
                disabled={true}
                variant="contained"
                color="primary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <DisplaySettingsIcon
                  style={{ marginRight: "5px" }}
                ></DisplaySettingsIcon>
                Details
              </Button>
            </Box>
            {/* <Box style={{ margin: "30px" }}>
              <Typography>
                <strong>Exchange:</strong>
              </Typography>
              <MixConfigs
                config={event.config.mix_configs || []}
                eventUid={event.uid}
              ></MixConfigs>
            </Box> */}
            <Box style={{ margin: "30px" }}>
              {/* <Box display="flex" alignItems={"center"} gap="10px">
                <VideoSettingsIcon></VideoSettingsIcon>
                <Typography>
                  <strong>Mix:</strong>
                </Typography>
              </Box> */}
              <MixConfigs
                config={event.config.mix_configs || []}
                eventUid={event.uid}
              ></MixConfigs>
            </Box>
            {/* <Box style={{ margin: "30px" }}>
              <Typography>
                <strong>Record:</strong>
              </Typography>
              <MixConfigs
                config={event.config.mix_configs || []}
                eventUid={event.uid}
              ></MixConfigs>
            </Box> */}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
