import React, { useState, useEffect, useRef } from "react";
import ReactFlow, { Controls, Background, Position } from "react-flow-renderer";
import { useTheme } from "@mui/material/styles";
import { Button, Box } from "@mui/material";

const Flow = ({ config }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const theme = useTheme();
  const reactFlowInstance = useRef(null);
  const [fitViewTriggered, setFitViewTriggered] = useState(false);

  const parseWorkflowData = (data) => {
    const parsedNodes = [];
    const parsedEdges = [];

    const layerSpacingX = 400;
    const nodeSpacingY = 50;
    const takerOffsetX = 200;
    const takerSpacingY = 50;
    const mixOffsetX = 500;
    const destinationOffsetX = 400;

    const sourceRoutes = {};
    const takersByRoute = {};

    // 1. Create source route nodes and takers for each route
    data.exchange_configs.forEach((exchange, exchangeIndex) => {
      exchange.api_config?.routes.forEach((route, routeIndex) => {
        const routeNodeId = `${route.route_uid}`;
        const routePosX = exchangeIndex * layerSpacingX;
        const routePosY = routeIndex * nodeSpacingY;

        // Create the route node
        parsedNodes.push({
          id: routeNodeId,
          data: { label: route.route_name },
          position: { x: routePosX, y: routePosY },
          style: {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.contrastText,
            padding: 10,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        });
        sourceRoutes[route.route_uid] = {
          id: routeNodeId,
          xPosition: routePosX,
          yPosition: routePosY,
        };

        // Create takers to the right of the route node
        takersByRoute[route.route_uid] = []; // Initialize takers for this route
        route.destinations.forEach((destination, destIndex) => {
          const takerNodeId = `${routeNodeId}-taker-${destIndex}`;
          const takerPosX = routePosX + takerOffsetX;
          const takerPosY = routePosY + destIndex * takerSpacingY;

          parsedNodes.push({
            id: takerNodeId,
            data: {
              label: destination.srt_name + ": " + destination.srt_port,
              port: destination.srt_port,
              name: destination.srt_name,
            },
            position: { x: takerPosX, y: takerPosY },
            style: {
              backgroundColor: theme.palette.primary.light,
              border: `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.contrastText,
              padding: 5,
              fontSize: "12px",
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
          });

          // Store taker info for adjustment later
          takersByRoute[route.route_uid].push({
            id: takerNodeId,
            // Store initial positions to adjust later
            initialPosX: takerPosX,
            initialPosY: takerPosY,
          });

          // Connect this taker to its specific route
          parsedEdges.push({
            id: `edge-${routeNodeId}-${takerNodeId}`,
            source: routeNodeId,
            target: takerNodeId,
            animated: false,
            sourceHandle: Position.Right,
            targetHandle: Position.Left,
          });
        });
      });
    });

    // 2. Create mix nodes and connect them to the correct taker based on matching port_id and srt_port
    data.mix_configs.forEach((mix, mixIndex) => {
      const mixNodeId = `mix-${mixIndex}`;
      const sourceRouteId = `${mix.source_route_uid}`;
      const sourceRoute = sourceRoutes[mix.source_route_uid];
      const mixPortId = mix.port_id; // Retrieve the port_id from the mix config
      console.log(sourceRoute);
      // Ensure the mix node is positioned to the right of its source route
      const mixPosX = sourceRoute.xPosition + mixOffsetX;
      const mixPosY = sourceRoute.yPosition + mixIndex * nodeSpacingY;

      const label = `${mix.type} mix #${mixIndex + 1}`;

      // Add the mix node
      parsedNodes.push({
        id: mixNodeId,
        data: { label },
        position: { x: mixPosX, y: mixPosY },
        style: {
          backgroundColor: theme.palette.success.light, // Bright green background
          border: `1px solid ${theme.palette.success.dark}`, // Darker green border
          color: theme.palette.success.contrastText, // High contrast text color for readability
          padding: 5,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      });

      // Find the taker with a matching srt_port for this mix's port_id within this route's takers
      const matchingTaker = takersByRoute[sourceRouteId]?.find((taker) => {
        const takerNode = parsedNodes.find((node) => node.id === taker.id);
        const takerPort = takerNode?.data.port.split("_").pop();
        return takerPort === mixPortId;
      });

      if (matchingTaker) {
        // Create an edge between the matching taker node and the mix node
        parsedEdges.push({
          id: `edge-${matchingTaker.id}-${mixNodeId}`,
          source: matchingTaker.id,
          target: mixNodeId,
          animated: false,
          sourceHandle: Position.Right,
          targetHandle: Position.Left,
        });
      }
    });

    // 3. Create and position destination routes to the right of their mix components
    data.mix_configs.forEach((mix, mixIndex) => {
      const destinationRouteId = `${mix.route_uid}`;
      const mixNode = parsedNodes.find((node) => node.id === `mix-${mixIndex}`);

      if (!mixNode) return; // Ensure mixNode exists

      // Set destination position relative to the mix node
      const destinationPosX = mixNode.position.x + destinationOffsetX;
      const takersToMove = takersByRoute[destinationRouteId] || []; // Get the takers for the destination route

      // Calculate the initial Y-position for the destination node based on the mix node and the number of takers
      let assummedTakerHeight = 15;
      const destinationPosY =
        mixNode.position.y -
        (takersToMove.length - 1) * (takerSpacingY + assummedTakerHeight);

      // Check if the destination route node already exists; otherwise, create it
      let destinationNode = parsedNodes.find(
        (node) => node.id === destinationRouteId
      );
      if (!destinationNode) {
        destinationNode = {
          id: destinationRouteId,
          data: { label: `Route to ${mix.route_uid}` },
          position: { x: destinationPosX, y: destinationPosY },
          style: {
            backgroundColor: theme.palette.secondary.light,
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.contrastText,
            padding: 5,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        };
        parsedNodes.push(destinationNode);
      } else {
        // Update existing node position if already present
        destinationNode.position = { x: destinationPosX, y: destinationPosY };
      }

      // Create an edge from the mix node to the destination route node
      parsedEdges.push({
        id: `edge-mix-${mixIndex}-destination-${destinationRouteId}`,
        source: mixNode.id,
        target: destinationRouteId,
        animated: false,
        sourceHandle: Position.Right,
        targetHandle: Position.Left,
      });

      // Adjust the position of takers for the specific destination route
      takersToMove.forEach((taker, index) => {
        const takerNode = parsedNodes.find((node) => node.id === taker.id);
        if (takerNode) {
          // Set each taker's position below the destination node with appropriate Y offset
          takerNode.position.x = destinationPosX + takerOffsetX;
          takerNode.position.y = destinationPosY + index * takerSpacingY;
        }
      });
    });

    // 3. Add record nodes from record_configs and connect them based on destination name match
    data.record_configs.forEach((recordConfig, recordIndex) => {
      const sourceRouteId = recordConfig.source_route_uid;
      const sourceRoute = sourceRoutes[sourceRouteId];

      if (!sourceRoute) return;

      // Set up default record node position to the right of mix nodes
      const recordPosX = sourceRoute.xPosition + mixOffsetX + 300;
      const recordPosY = sourceRoute.yPosition + recordIndex * nodeSpacingY;

      const label = `${recordConfig.type} record #${recordIndex + 1}`;

      // Add the record node
      parsedNodes.push({
        id: `record-${recordIndex}`,
        data: { label },
        position: { x: recordPosX, y: recordPosY },
        style: {
          backgroundColor: theme.palette.warning.light,
          border: `1px solid ${theme.palette.warning.main}`,
          color: theme.palette.warning.contrastText,
          padding: 5,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      });

      // Get the record type from recordConfig (e.g., "nablet")
      const recordType = recordConfig.type.toLowerCase();

      // Find takers by matching destination name with record type and index
      const matchingTaker = takersByRoute[sourceRouteId]?.find((taker) => {
        const takerNode = parsedNodes.find((node) => node.id === taker.id);
        return (
          takerNode?.data.name.toLowerCase().includes(recordType) &&
          takerNode?.data.name.includes(`_${recordIndex}`)
        );
      });

      if (matchingTaker) {
        // Adjust record node position to be to the right of its matching taker
        const takerNode = parsedNodes.find(
          (node) => node.id === matchingTaker.id
        );
        if (takerNode) {
          const adjustedRecordPosX = takerNode.position.x + takerOffsetX + 100;
          parsedNodes[parsedNodes.length - 1].position.x = adjustedRecordPosX; // Update record node position
          parsedNodes[parsedNodes.length - 1].position.y = takerNode.position.y; // Align vertically with taker
        }

        // Create a yellow edge between the matching taker node and the record node
        parsedEdges.push({
          id: `edge-${matchingTaker.id}-record-${recordIndex}`,
          source: matchingTaker.id,
          target: `record-${recordIndex}`,
          animated: false,
          sourceHandle: Position.Right,
          targetHandle: Position.Left,
        });
      }
    });

    setNodes(parsedNodes);
    setEdges(parsedEdges);
  };

  useEffect(() => {
    if (config && config.exchange_configs && config.mix_configs) {
      parseWorkflowData(config);
    } else {
      console.warn("Config data is missing or incomplete");
    }

    // Cleanup to reset nodes and edges when component is unmounted
    return () => {
      setNodes([]);
      setEdges([]);
      setFitViewTriggered(false); // Reset fitView trigger
    };
  }, [config, theme]);

  const handleFitView = () => {
    if (reactFlowInstance.current && !fitViewTriggered) {
      reactFlowInstance.current.fitView();
      setFitViewTriggered(true);
    }
  };

  return (
    <Box>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        style={{ width: "100%", height: "90vh" }}
        onInit={(instance) => (reactFlowInstance.current = instance)}
        onNodesChange={handleFitView}
        onEdgesChange={handleFitView}
      >
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </Box>
  );
};

export default Flow;
