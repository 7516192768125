import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Typography,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Box,
  Card,
  Button,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { useApiRequest } from "../Utility/useAPIRequest";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { MyContext } from "../../context/Context";
import ContentCopy from "@mui/icons-material/ContentCopy";

export const sanitizePrefix = (prefix) =>
  prefix
    .trim()
    .replace(/\/+/g, "/")
    .replace(/(.*?)\/?$/, "$1/")
    .replace(/^\//, "");

export const formatFileSize = (size, digits = 2) => {
  if (size === 0) return "0 B";
  const units = ["B", "KB", "MB", "GB", "TB", "PB"];
  const exp = Math.floor(Math.log(size) / Math.log(1024));
  const converted = parseFloat(size / Math.pow(1024, exp));
  return `${converted.toFixed(digits)} ${units[exp]}`;
};

const S3Explorer = ({ initialBucketName }) => {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [currentPath, setCurrentPath] = useState("");
  const [status, setStatus] = useState("loading");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const { makeApiRequest } = useApiRequest();
  const theme = useTheme();
  const [items, setItems] = useState([]);

  // Effect to update items when state.status.storage.s3 changes
  useEffect(() => {
    if (state.status.storage?.s3) {
      setItems(state.status.storage.s3);
    }
  }, [state.status.storage?.s3]);

  // const listAllItems = async () => {
  //   const body = {
  //     command: "list_all_items",
  //     bucket_name: initialBucketName,
  //   };

  //   const options = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(body),
  //   };

  //   try {
  //     const result = await makeApiRequest(
  //       process.env.REACT_APP_COMMAND_S3,
  //       options,
  //       null,
  //       false
  //     );

  //     if (result.success) {
  //       setItems(result.data.response || []);
  //       setStatus("success");
  //     } else {
  //       setMessage(result.error);
  //       setSeverity("error");
  //       setStatus("error");
  //     }
  //   } catch (error) {
  //     setMessage("Error executing command");
  //     setSeverity("error");
  //     setStatus("error");
  //     console.error(error);
  //   }
  // };

  const navigateTo = (path) => {
    setCurrentPath(path);
  };

  const handleBreadcrumbClick = (index) => {
    const pathSegments = currentPath.split("/").slice(0, index + 1);
    const newPath = pathSegments.join("/") + "/";
    navigateTo(newPath);
  };

  const listContents = (items, path) => {
    const folderSet = new Set();
    const fileSet = new Set();
    const adjustedPath = path ? path : "";

    items.forEach((item) => {
      if (item.Key.startsWith(adjustedPath)) {
        const relativePath = item.Key.replace(adjustedPath, "").split("/");
        if (relativePath.length > 1) {
          folderSet.add(adjustedPath + relativePath[0] + "/");
        } else if (relativePath.length === 1 && relativePath[0]) {
          fileSet.add(item.Key);
        }
      }
    });

    const folders = Array.from(folderSet).map((folder) => ({ Key: folder }));
    const files = Array.from(fileSet).map((file) =>
      items.find((item) => item.Key === file)
    );

    return { folders, files };
  };

  const { folders, files } = listContents(items, currentPath);

  const downloadFile = async (fileKey) => {
    const body = {
      command: "download_file",
      bucket_name: initialBucketName,
      folder_name: currentPath,
      file_name: fileKey.replace(currentPath, ""),
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const result = await makeApiRequest(
        process.env.REACT_APP_COMMAND_S3,
        options,
        onSuccessfulModification
      );

      if (result.success) {
        window.open(result.data.response, "_blank");
      } else {
        setMessage(result.error);
        setSeverity("error");
      }
    } catch (error) {
      setMessage("Error executing download");
      setSeverity("error");
      console.error(error);
    }
  };

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const body = {
      command: "upload_file",
      bucket_name: initialBucketName,
      folder_name: currentPath,
      file_name: file.name,
      content_type: file.type,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const result = await makeApiRequest(
        process.env.REACT_APP_COMMAND_S3,
        options,
        onSuccessfulModification
      );

      if (result.success) {
        const presignedUrl = result.data.response;

        // Upload file to S3 using presigned URL
        const uploadResponse = await fetch(presignedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        });

        if (uploadResponse.ok) {
          setMessage("File uploaded successfully");
          setSeverity("success");
          //listAllItems(); // Refresh the list
        } else {
          const errorText = await uploadResponse.text();
          console.error("Upload failed:", errorText);
          setMessage(`Failed to upload file: ${errorText}`);
          setSeverity("error");
        }
      } else {
        setMessage(result.error);
        setSeverity("error");
      }
    } catch (error) {
      setMessage("Error executing upload");
      setSeverity("error");
      console.error("Upload error:", error);
    }
  };

  const getPublicUrl = async (fileKey) => {
    const body = {
      command: "get_public_url",
      bucket_name: initialBucketName,
      folder_name: currentPath,
      file_name: fileKey.replace(currentPath, ""),
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const result = await makeApiRequest(
        process.env.REACT_APP_COMMAND_S3,
        options,
        onSuccessfulModification
      );

      if (result.success) {
        const publicUrl = result.data.response.public_url;
        navigator.clipboard.writeText(publicUrl); // Copy to clipboard
        console.log(publicUrl); // Log to console
        setMessage("Public URL copied to clipboard");
        setSeverity("success");
      } else {
        setMessage(result.error);
        setSeverity("error");
      }
    } catch (error) {
      setMessage("Error fetching public URL");
      setSeverity("error");
      console.error(error);
    }
  };

  const deleteFile = async (fileKey) => {
    const body = {
      command: "delete_file",
      bucket_name: initialBucketName,
      file_name: fileKey,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const result = await makeApiRequest(
        process.env.REACT_APP_COMMAND_S3,
        options,
        onSuccessfulModification
      );

      if (result.success) {
        setMessage("File deleted successfully");
        setSeverity("success");
        //listAllItems(); // Refresh the list
      } else {
        setMessage(result.error);
        setSeverity("error");
      }
    } catch (error) {
      setMessage("Error executing delete");
      setSeverity("error");
      console.error(error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        File Explorer
      </Typography>
      <Card>
        <Breadcrumbs
          style={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            padding: "10px",
          }}
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link onClick={() => navigateTo("")} style={{ cursor: "pointer" }}>
            <HomeIcon fontSize="small" />
          </Link>
          {currentPath
            .split("/")
            .filter(Boolean)
            .map((segment, index) => (
              <Link
                key={index}
                onClick={() => handleBreadcrumbClick(index)}
                style={{ cursor: "pointer" }}
              >
                {segment}
              </Link>
            ))}
        </Breadcrumbs>
      </Card>
      <Button
        variant="contained"
        color="primary"
        component="label"
        startIcon={<UploadIcon />}
        style={{ margin: "16px 0" }}
      >
        Upload File
        <input type="file" hidden onChange={uploadFile} />
      </Button>
      {!state.status.storage && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      {status === "error" && (
        <Alert severity="error">Failed to fetch data: {message}</Alert>
      )}
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last Modified</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {folders.length > 0
              ? folders.map((folder, index) => {
                  const folderName = folder.Key.replace(currentPath, "").split(
                    "/"
                  )[0];
                  return (
                    <TableRow
                      key={index}
                      onClick={() => navigateTo(folder.Key)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        <IconButton>
                          <FolderIcon />
                        </IconButton>
                        {folderName}
                      </TableCell>
                      <TableCell>—</TableCell>
                      <TableCell>—</TableCell>
                      <TableCell>—</TableCell>
                    </TableRow>
                  );
                })
              : null}
            {files.length > 0
              ? files.map((file, index) => {
                  const fileName = file.Key.replace(currentPath, "").split(
                    "/"
                  )[0];
                  return (
                    <TableRow key={index} style={{ cursor: "default" }}>
                      <TableCell component="th" scope="row">
                        <IconButton>
                          <DescriptionIcon />
                        </IconButton>
                        {fileName}
                      </TableCell>
                      <TableCell>
                        {new Date(file.LastModified).toLocaleString()}
                      </TableCell>
                      <TableCell>{formatFileSize(file.Size)}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => downloadFile(file.Key)}
                          startIcon={<DownloadIcon />}
                        >
                          Download
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => deleteFile(file.Key)}
                          startIcon={<DeleteIcon />}
                          style={{ marginLeft: "8px" }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getPublicUrl(file.Key)}
                          startIcon={<ContentCopy />}
                          style={{ marginLeft: "8px" }}
                        >
                          Copy URL
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              : folders.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No items found
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setMessage("")}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default S3Explorer;
