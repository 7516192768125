import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { v4 as uuidv4 } from "uuid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import JsonImporter from "../../Utility/JsonImporter";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const AddTournamentsForm = ({ open, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    stages: [],
    teams: [],
    thumbnail_url:
      "https://htmlcolorcodes.com/assets/images/colors/slate-gray-color-solid-background-1920x1080.png",
  });

  const [stageInput, setStageInput] = useState("");
  const [teamInput, setTeamInput] = useState({
    uid: uuidv4(),
    alias: "",
    logo_url: "",
    primary_color: "#00000",
    short_code: "",
  });

  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleStageChange = (event) => {
    setStageInput(event.target.value);
  };

  const handleTeamChange = (event) => {
    const { name, value } = event.target;
    setTeamInput({ ...teamInput, [name]: value });
  };

  const addStage = () => {
    if (stageInput) {
      setFormData((prevData) => ({
        ...prevData,
        stages: [...prevData.stages, stageInput],
      }));
      setStageInput("");
    }
  };

  const removeStage = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      stages: prevData.stages.filter((_, i) => i !== index),
    }));
  };

  const addTeam = () => {
    if (
      teamInput.uid &&
      teamInput.alias &&
      teamInput.logo_url &&
      teamInput.primary_color &&
      teamInput.short_code
    ) {
      setFormData((prevData) => ({
        ...prevData,
        teams: [...prevData.teams, teamInput],
      }));
      setTeamInput({
        uid: uuidv4(),
        alias: "",
        logo_url: "",
        primary_color: "",
        short_code: "",
      });
    }
  };

  const removeTeam = (uid) => {
    setFormData((prevData) => ({
      ...prevData,
      teams: prevData.teams.filter((team) => team.uid !== uid),
    }));
  };

  const validateData = () => {
    if (!formData.alias || !formData.thumbnail_url) {
      setError("Required field missing!");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = () => {
    if (validateData()) {
      onAdd(formData);
      handleClose();
    }
  };

  const handleClose = () => {
    setFormData({
      uid: uuidv4(),
      alias: "",
      stages: [],
      teams: [],
      thumbnail_url:
        "https://htmlcolorcodes.com/assets/images/colors/slate-gray-color-solid-background-1920x1080.png",
    });
    setStageInput("");
    setTeamInput({
      uid: uuidv4(),
      alias: "",
      logo_url: "",
      primary_color: "",
      short_code: "",
    });
    setError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1"> Add New Tournament</Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" style={{ marginBottom: "1rem" }}>
            {error}
          </Alert>
        )}
        <TextField
          autoFocus
          margin="dense"
          name="uid"
          label="UID"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          name="thumbnail_url"
          label="Thumbnail URL"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.thumbnail_url}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="Add Stage"
          type="text"
          fullWidth
          variant="outlined"
          value={stageInput}
          onChange={handleStageChange}
        />
        <Button
          onClick={addStage}
          color="primary"
          variant="contained"
          style={{ marginTop: "10px" }}
        >
          Add Stage
        </Button>
        <List>
          {formData.stages.map((stage, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeStage(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={stage} />
            </ListItem>
          ))}
        </List>
        <TextField
          margin="dense"
          name="uid"
          label="Team UID"
          type="text"
          fullWidth
          variant="outlined"
          value={teamInput.uid}
          onChange={handleTeamChange}
        />
        <TextField
          margin="dense"
          name="alias"
          label="Team Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={teamInput.alias}
          onChange={handleTeamChange}
        />
        <TextField
          margin="dense"
          name="logo_url"
          label="Team Logo URL"
          type="text"
          fullWidth
          variant="outlined"
          value={teamInput.logo_url}
          onChange={handleTeamChange}
        />
        <TextField
          margin="dense"
          name="primary_color"
          label="Team Primary Color"
          type="text"
          fullWidth
          variant="outlined"
          value={teamInput.primary_color}
          onChange={handleTeamChange}
        />
        <TextField
          margin="dense"
          name="short_code"
          label="Team Short Code"
          type="text"
          fullWidth
          variant="outlined"
          value={teamInput.short_code}
          onChange={handleTeamChange}
        />
        <Button
          onClick={addTeam}
          color="primary"
          variant="contained"
          style={{ marginTop: "10px" }}
        >
          Add Team
        </Button>
        <List>
          {formData.teams.map((team) => (
            <ListItem
              key={team.uid}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeTeam(team.uid)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${team.alias} (${team.short_code})`}
                secondary={team.primary_color}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTournamentsForm;
