import React from "react";
import { Grid, Typography, Link, Paper } from "@mui/material";

const StreamhubDetails = ({ streamhub }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        Distributions:{" "}
        {Array.isArray(streamhub["distributions"])
          ? streamhub["distributions"].join(", ")
          : ""}{" "}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          Host:{" "}
          <Link href={`https://${streamhub["host"]}`} underline="hover">
            {streamhub["host"]}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Device ID: {streamhub["device_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Region: {streamhub["region"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>AWS Instance ID: {streamhub["aws_instance_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {streamhub["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {streamhub["internal_ip"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default StreamhubDetails;
