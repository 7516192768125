import React, { createContext, useContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentSnackbar, setCurrentSnackbar] = useState(undefined);

  const triggerSnackbar = (newMessage, newSeverity = 'info') => {
    setSnackPack((prev) => [...prev, { message: newMessage, severity: newSeverity, key: new Date().getTime() }]);
  };

  React.useEffect(() => {
    if (snackPack.length && !currentSnackbar) {
      // Set a new snackbar to be displayed
      setCurrentSnackbar({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && currentSnackbar && open) {
      // Close an active snackbar if there is another one waiting to be displayed
      setOpen(false);
    }
  }, [snackPack, currentSnackbar, open]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setCurrentSnackbar(undefined);
  };

  return (
    <SnackbarContext.Provider value={{ triggerSnackbar }}>
      {children}
      <Snackbar
        key={currentSnackbar ? currentSnackbar.key : undefined}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {currentSnackbar ? (
          <Alert onClose={handleClose} severity={currentSnackbar.severity} sx={{ width: '100%' }}>
            {currentSnackbar.message}
          </Alert>
        ) : null}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
