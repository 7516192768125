import React, { useState, useContext, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button, Paper } from "@mui/material";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import GatewayConfig from "./GatewayConfig";
import ReplayIcon from "@mui/icons-material/Replay";
import GatewayDetails from "./GatewayDetails";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Gateway({ gateway, index }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const [routesInfo, setRoutesInfo] = useState(null);
  const [loading, setLoading] = useState(false); // New state for tracking loading

  const handleDeleteAll = async () => {
    try {
      await requestConfirm("Are you sure you want delete all routes?");
      let url = process.env.REACT_APP_TASKS_URL;
      let allRoutes = [];
      routesInfo[0][0]?.config.forEach((element) => {
        allRoutes.push({ route_name: element.name });
      });
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            command: "delete_gateways_routes",
            input: { routes: allRoutes, gateways: [gateway] },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStart = async (gateways) => {
    try {
      await requestConfirm("Are you sure you want to start this instance?");
      let url = process.env.REACT_APP_START_GATEWAYS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gateways: gateways,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStop = async (gateways) => {
    try {
      await requestConfirm("Are you sure you want to stop this instance?");
      let url = process.env.REACT_APP_STOP_GATEWAYS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gateways: gateways,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const getGatewayRouteInfo = async (gateway) => {
    setLoading(true); // Start loading
    try {
      let url = process.env.REACT_APP_STATUS_URL;
      const result = await makeApiRequest(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          command: "get_gateways_routes_status",
          input: { gateways: [gateway] },
        }),
      });
      console.log(result);
      setRoutesInfo(result.data.response);
      return;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    } finally {
      setLoading(false); // Stop loading regardless of result
    }
  };

  const gatewayStatus = state.status.gateways?.find((gateway_status) =>
    gateway_status.InstanceStatuses.some(
      (instance) => instance.InstanceId === gateway["aws_instance_id"]
    )
  );
  const instanceState = gatewayStatus ? (
    <Typography>
      {" "}
      {gatewayStatus.InstanceStatuses[0].InstanceState.Name}{" "}
    </Typography>
  ) : (
    <LinearProgress style={{ width: "50px" }} />
  );
  return (
    <div>
      {ConfirmDialog}
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <DesktopWindowsIcon
              color={
                gatewayStatus
                  ? gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                    ? "primary"
                    : "secondary"
                  : "action"
              }
            />
            <Typography style={{ minWidth: "270px" }}>
              {gateway["host"]}
            </Typography>
            <Box sx={{ display: "flex", flexGrow: 1 }}>{instanceState}</Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "10px",
                borderTopRightRadius: "0px",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleStart([gateway])}
              disabled={
                !(
                  gatewayStatus &&
                  gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                    "stopped"
                )
              }
            >
              Start
            </Button>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopLeftRadius: "0px",
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleStop([gateway])}
              disabled={
                !(
                  gatewayStatus &&
                  gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                )
              }
            >
              Stop
            </Button>
          </div>
          <Paper style={{ padding: "20px", marginBottom: 20 }}>
            <GatewayDetails gateway={gateway}></GatewayDetails>
            <div>
              <Button
                style={{
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  marginTop: "50px",
                  marginLeft: "10px",
                }}
                variant="contained"
                color="primary"
                onClick={() => getGatewayRouteInfo(gateway)}
                disabled={
                  !(
                    gatewayStatus &&
                    gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                      "running"
                  )
                }
              >
                {!routesInfo ? (
                  <Box style={{ display: "flex" }}>
                    <Typography>Load Routes</Typography>
                    <ExpandMoreIcon className="icon"></ExpandMoreIcon>
                  </Box>
                ) : (
                  <ReplayIcon className="icon"></ReplayIcon>
                )}
              </Button>
              {!routesInfo ? null : (
                <Button
                  style={{
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    marginTop: "50px",
                    marginLeft: "85%",
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteAll()}
                  disabled={
                    !(
                      gatewayStatus &&
                      gatewayStatus.InstanceStatuses &&
                      gatewayStatus.InstanceStatuses[0] &&
                      gatewayStatus.InstanceStatuses[0].InstanceState &&
                      gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                        "running"
                    ) ||
                    (routesInfo &&
                      routesInfo[0] &&
                      routesInfo[0][0] &&
                      routesInfo[0][0].config &&
                      routesInfo[0][0].config.length === 0)
                  }
                >
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Typography>Delete All Routes</Typography>
                    <DeleteIcon className="icon"></DeleteIcon>
                  </Box>
                </Button>
              )}
            </div>
            {loading && <LinearProgress />}{" "}
            {routesInfo && (
              <GatewayConfig
                configs={routesInfo[0]}
                gateway={gateway}
                triggerReload={() => getGatewayRouteInfo(gateway)}
              />
            )}
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
