import React, { useContext } from "react";
import { MyContext } from "../../context/Context";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SetDarkMode() {
  const { state, dispatch } = useContext(MyContext);

  const handleChange = (event) => {
    dispatch({ type: "SET_DARKMODE", payload: event.target.checked });
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={state.settings.darkMode}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={
          <Box display="flex" alignItems="center" gap={1}>
            {state.settings.darkMode ? (
              <Brightness4Icon />
            ) : (
              <Brightness7Icon />
            )}
            <Typography variant="body1">
              {state.settings.darkMode ? "Dark Mode" : "Light Mode"}
            </Typography>
          </Box>
        }
      />
    </FormGroup>
  );
}
